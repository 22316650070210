/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import "./DealDtlPop.css";
// import profileImg from "../../assets/images/profile-02.png";
import ImagePopup from '../../components/reuseable/ImagePopup';
 

const DealDtlPop = ({ Dealer, closeModal, BASE_URL, onAccept, onReject }) => {

  const [popupImageSrc, setPopupImageSrc] = useState('');
  const [imagePopupOpen, setImagePopupOpen] = useState(false);

  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setImagePopupOpen(true);
  };
  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };

  return (
    <div className="Dealpopmodal-overlay" >
      <div className="Dealpopmodal" >
        <h3>Dealer Details</h3>
        <button className="Dealpopmodal-close-btn" onClick={closeModal}>
          ×
        </button>
        <div className="Dealpopmodal-content" >
          <div className="imgDIVDealpop">
            <img

              src={Dealer.usr_profile && Dealer.usr_profile.trim() !== "" ? `${BASE_URL}/${Dealer.usr_profile}` : require('../../assets/images/profile-02.png')}
              alt={`${Dealer.usr_name} Photo`}
              className="Dealpopmodal-image"
              onClick={() => Dealer.usr_profile && Dealer.usr_profile.trim() !== "" && openImagePopup(`${BASE_URL}/${Dealer.usr_profile}`)}
            />
          </div>
          <div className="Dealpopmodal-history">
            <div className="Dealpopdriver-details">
              <div className="Dealpopmodal-details">
                <h4>Personal Details</h4>
                <p>
                  <strong>Dealer Name :</strong> {Dealer.usr_name} &nbsp;
                  <span style={{ color: getColor(Dealer.vehAprove) }}>
                    ({Dealer.Ad_Verify === 0 ? "Pending" : Dealer.Ad_Verify === 1 ? "Active" : Dealer.Ad_Verify === 2 ? "Deactive" : "Unknown"})
                  </span>
                </p>
                <p>
                  <strong>Dealer Number :</strong> {Dealer.usr_num}
                </p>
                <p>
                  <strong>Address :</strong> {Dealer.address}
                </p>
                <p>
                  <strong>Pincode :</strong> {Dealer.pincode}
                </p>
                <p>
                  <strong>Dealer Status :</strong> {Dealer.auth_sts}
                </p>
                <p>
                  <strong>Licence Number :</strong> {Dealer.licence_num}
                </p>
                <p>
                  <strong>Experience :</strong> {Dealer.experience} years
                </p>

              </div>
              <div className="Dealpopmodal-details">
                <div>
                  <h4>Documents</h4>
                </div>
                <div className="Dealdoc-details">
                  <img
                    src={Dealer.id_proof && Dealer.id_proof.trim() !== "" ? `${BASE_URL}/${Dealer.id_proof}` : require('../../assets/images/no_img.jpg')}
                    alt={'Dealers Adhar'}
                    className="Dealpopdriver-photo"
                    onClick={() => Dealer.id_proof && Dealer.id_proof.trim() !== "" && openImagePopup(`${BASE_URL}/${Dealer.id_proof}`)}
                  />
                  <img
                    src={Dealer.license_path && Dealer.license_path.trim() !== "" ? `${BASE_URL}/${Dealer.license_path}` : require('../../assets/images/no_img.jpg')}
                    alt={'Licence Image'}
                    className="Dealpopdriver-photo"
                    onClick={() => Dealer.license_path && Dealer.license_path.trim() !== "" && openImagePopup(`${BASE_URL}/${Dealer.license_path}`)}
                  />
                </div>
              </div>

            </div>

          </div>
        </div>
        <div className="Dealpopmodal-actions">
          <button className="vehicle-requests__action-btn accept-btn" onClick={onAccept}>
            Accept
          </button>
          <button className="vehicle-requests__action-btn reject-btn" onClick={onReject}>
            Reject
          </button>
          <button className="vehicle-requests__action-btn delete-btn" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
      {imagePopupOpen && (
        <ImagePopup
          src={popupImageSrc}
          alt="Vehicle"
          onClose={closeImagePopup}
        />
      )}
    </div>

  );
};

const getColor = (verifyStatus) => {
  switch (verifyStatus) {
    case 0:
      return "orange"; // Pending
    case 1:
      return "green"; // Accepted
    case 2:
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};

export default DealDtlPop;
