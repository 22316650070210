import React from "react";
import { FaChair, FaBolt, FaTachometerAlt, FaCogs, FaBriefcase } from "react-icons/fa";
import "./FeatureList.css";

// Mapping feature types to icons
const iconMapping = {
  seats: <FaChair />,
  horsepower: <FaBolt />,
  speed: <FaTachometerAlt />,
  transmission: <FaCogs />,
  luggage: <FaBriefcase />,
};

const FeatureList = ({ features }) => {
  return (
    <div className="features-overlay">
      {features
        .filter(feature => feature.value) // Remove features with empty/null values
        .map((feature, index) => (
          <div key={index} className={`feature-item ${feature.color}`}>
            <div className="feature-icon">{iconMapping[feature.type]}</div>
            <span>{feature.value}</span>
          </div>
        ))}
    </div>
  );
};

export default FeatureList;
