/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import Modal from "react-modal";
import { BASE_URL } from "../../api/config";
import "./viewstyle/viewvehicledtl.css";
import FeatureList from "../../components/reuseable/FeatureList";
import ConfirmationModal from "../../components/Model/ConfirmationModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Swal from "sweetalert2";
import profileImg from "../../assets/images/profile-02.png";
import { apiUrl } from "../../api/api_url";

const ViewVehicledtl = () => {
  const location = useLocation();
  const { vehicle } = location.state || {};
  const [vehicleGallery, setVehicleGallery] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false); // Modal state
  const [actionType, setActionType] = useState(""); // Action type for confirmation
  const [vehicleToApprove, setVehToApprove] = useState(null);

  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0); // Index of the clicked image

  const [VehicleActivateAccess, setVehicleActivateAccess] = useState(false);

  const staffAccess = JSON.parse(localStorage.getItem("staffaccess") || "{}");
  const whoislog = localStorage.getItem("whoislog");

  useEffect(() => {
    const staffAccess = JSON.parse(
      localStorage.getItem("staffaccess") || "{}"
    );
    const whoislog = localStorage.getItem("whoislog");
    const accessCategory = staffAccess.access_category
      ? JSON.parse(staffAccess.access_category)
      : {};

    if (
      (staffAccess && accessCategory?.["Vehicle"]?.ActiveDeactivate) ||
      whoislog === "Admin"
    ) {
      setVehicleActivateAccess(true);
    } else {
      setVehicleActivateAccess(false);
    }
    if (vehicle) {
      fetchVehGallery();
    }
  }, [vehicle]);

  const fetchVehGallery = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        `${BASE_URL}${apiUrl.GET_GUEST_GALLERYIMAGE}/${vehicle.token}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch vehicle gallery");
      }

      const data = await response.json();
      setVehicleGallery(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching vehicle gallery:", error);
      setIsLoading(false);
    }
  };

  const openModal = (id, token, verify, dealId) => {
    setActionType(
      verify === 1 ? "Activate" : verify === 2 ? "Deactivate" : "Approve"
    );
    setVehToApprove({ id, token, verify, dealId });
    setModalOpen(true);
  };

  // Open the image modal with the clicked image as the first slide
  const openImageModal = (index) => {
    setCurrentSlide(index); // Set the initial slide to the clicked image
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  // Slick slider settings
  const sliderSettings = {
    initialSlide: currentSlide, // Start with the clicked image
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current), // Update the current slide index
  };

  const handleAdminApprove = async () => {
    if (!vehicleToApprove) return;

    const { id, token, verify, dealId } = vehicleToApprove;
    const accessToken = localStorage.getItem("accessToken");
    console.log("VehicleToApprove", vehicleToApprove);
    console.log("staffAccess", staffAccess);

    console.log(id, token, verify, dealId, whoislog, staffAccess.staff_logid);
    setModalOpen(false);
    try {
      const response = await axios.post(
        `${BASE_URL}${apiUrl.VEHICLE_APPROVAL_REQ}`,
        {
          id: id.toString(),
          tokn: token.toString(),
          verify: verify.toString(),
          dealid: dealId.toString(),

          staffrole: whoislog,
          staffId: staffAccess.staff_logid ?? "0",
          action: verify === 1 ? "Activate" : verify === 2 ? "Deactive" : "",
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const message = response.data;
        console.log("Message:", message);

        if (verify === 1) {
          await Swal.fire({
            title: "Vehicle Reactivated",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
            customClass: {
              popup: "custom-popup",
              title: "custom-title",
              content: "custom-content",
            },
          });

          window.history.back();
        } else if (verify === 2) {
          await axios.post(
            `${BASE_URL}${apiUrl.UPDATE_REJECT_REASON}`,
            {
              action: "VehicleReject",
              id: id.toString(),
              tokn: token.toString(),
              dealid: dealId.toString(),
              rej_rsn: "Your Rejection Reason",
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          await Swal.fire({
            title: "Vehicle Deactivated",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
            customClass: {
              popup: "custom-popup",
              title: "custom-title",
              content: "custom-content",
            },
          });

          window.history.back();
        }

        // setTimeout(() => {
        //   window.history.back();
        // }, 2000);
      } else {
        alert("Action failed");
      }
    } catch (error) {
      console.error("Error in ADMIN approve function:", error);
    }

    setModalOpen(false); // Close the modal after the action is taken
  };

  if (isLoading)
    return (
      <center>
        <div className="waitingspinner"></div>
      </center>
    );

  const features = [
    {
      type: "seats",
      value: vehicle.seat ? `${vehicle.seat}` : "",
      color: "green",
    },
    {
      type: "horsepower",
      value: vehicle.power ? `${vehicle.power}` : "",
      color: "red",
    },
    {
      type: "speed",
      value: vehicle.speed ? `${vehicle.speed}` : "",
      color: "blue",
    },
    {
      type: "transmission",
      value: vehicle.gear ? vehicle.gear : "",
      color: "pink",
    },
    {
      type: "luggage",
      value: vehicle.bag ? `${vehicle.bag}` : "",
      color: "yellow",
    },
  ];

  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="viewvehicle-container">
          {vehicle ? (
            <>
              <div className="viewvehicle_activetitleTop">
                <h2 className="View__car-title">
                  {vehicle.car_type}
                  <span className="car_name">{vehicle.car_name}</span>
                </h2>
                {VehicleActivateAccess && (
                  <button
                    className="viewvehicle_activeDeactive_btn"
                    style={{ backgroundColor: getbtnColor(vehicle.vehAprove) }}
                    onClick={() =>
                      openModal(
                        vehicle.id,
                        vehicle.token,
                        vehicle.vehAprove === 1
                          ? 2
                          : vehicle.vehAprove === 2
                          ? 1
                          : vehicle.vehAprove === 0
                          ? 1
                          : 0,
                        vehicle.dealid
                      )
                    }
                  >
                    {vehicle.vehAprove === 1
                      ? "Deactivate"
                      : vehicle.vehAprove === 2
                      ? "Activate"
                      : vehicle.vehAprove === 0
                      ? "Approve"
                      : "Unknown"}
                  </button>
                )}
              </div>

              <div className="View__car-top">
                <div className="car-container">
                  <div className="car-img-container">
                    <img
                      src={`${BASE_URL}/${vehicle.car_photo}`}
                      alt="Car"
                      className="car-image"
                    />
                  </div>
                </div>

                <div className="vehicle__history">
                  <div className="vehicle-details">
                    <div className="driver-info">
                      <div className="vehicle-info">
                        <p>
                          <strong>Vehicle Name:</strong> {vehicle.car_name}{" "}
                          &nbsp;
                          <span style={{ color: getColor(vehicle.vehAprove) }}>
                            (
                            {vehicle.vehAprove === 0
                              ? "Pending"
                              : vehicle.vehAprove === 1
                              ? "Active"
                              : vehicle.vehAprove === 2
                              ? "Deactive"
                              : "Unknown"}
                            )
                          </span>
                        </p>
                        <p>
                          <strong>Vehicle Number:</strong> {vehicle.car_number}
                        </p>
                        <p>
                          <strong>Cost per KM:</strong> ₹ {vehicle.price_per_km}
                        </p>
                        <p>
                          <strong>Minimum KM :</strong> {vehicle.minkm_cost} km
                        </p>
                        <p>
                          <strong>Cost per Day:</strong> ₹{" "}
                          {vehicle.one_day_rent}
                        </p>
                        <p>
                          <strong>Maximum KM/day:</strong> {vehicle.maxkm_day} km
                        </p>
                      </div>
                      <FeatureList features={features} />
                    </div>
                    <h4 className="driver-info-heading">Driver Details</h4>
                    <div className="driver-info">
                      <div className="driver-details">
                        <p>
                          <strong>Driver Name:</strong> {vehicle.usr_name}
                        </p>
                        <p>
                          <strong>Driver Number:</strong> {vehicle.usr_num}
                        </p>
                        <p>
                          <strong>Experience:</strong> {vehicle.experience}{" "}
                          years
                        </p>
                        <p>
                          <strong>Licence Number:</strong> {vehicle.licence_num}
                        </p>
                      </div>
                      <img
                        src={
                          vehicle.usr_profile
                            ? `${BASE_URL}/${vehicle.usr_profile}`
                            : profileImg
                        }
                        alt={vehicle.usr_name || "No Name Available"}
                        className="driver-photo"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Vehicle Gallery */}
              <h3 className="viewvehicle_headmargin">Vehicle Gallery</h3>
              <div className="viewvehicle_Gal-list">
                {vehicleGallery.length > 0 ? (
                  vehicleGallery.map((gallery, index) => (
                    <div
                      key={gallery.id}
                      className="galvehimgdiv"
                      onClick={() => openImageModal(index)}
                    >
                      <img
                        src={`${BASE_URL}/${gallery.car_photo}`}
                        alt={`gallery img`}
                        className="gallery-photo"
                      />
                    </div>
                  ))
                ) : (
                  <p className="no--gallry">No Gallery found.</p>
                )}
              </div>

              <ConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleAdminApprove}
                message={`Are you sure you want to ${actionType} this Vehicle?`}
              />
            </>
          ) : (
            <p className="headmargin">No Vehicle data available</p>
          )}

          {/* Image Modal for viewing images in slider */}
          <Modal
            isOpen={isImageModalOpen}
            onRequestClose={closeImageModal}
            contentLabel="Image Gallery"
            className="modal"
            overlayClassName="modal-overlay"
          >
            <button onClick={closeImageModal} className="sliderclose-btn">
              &times;
            </button>

            {vehicleGallery.length > 1 ? (
              <Slider {...sliderSettings}>
                {vehicleGallery.map((gallery) => (
                  <div key={gallery.id} className="slider-img">
                    <img
                      src={`${BASE_URL}/${gallery.car_photo}`}
                      alt={`gallery img`}
                      className="slider-photo"
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              vehicleGallery.length === 1 && (
                <div className="slider-img">
                  <img
                    src={`${BASE_URL}/${vehicleGallery[0].car_photo}`}
                    alt={`gallery img`}
                    className="slider-photo"
                  />
                </div>
              )
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

const getColor = (verifyStatus) => {
  switch (verifyStatus) {
    case 0:
      return "orange"; // Pending
    case 1:
      return "green"; // Accepted
    case 2:
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};

const getbtnColor = (verifyStatus) => {
  switch (verifyStatus) {
    case 0:
      return "orange"; // Pending
    case 2:
      return "darkgreen"; // Accepted
    case 1:
      return "darkred"; // Finished
    default:
      return "gray"; // Unknown
  }
};

export default ViewVehicledtl;
