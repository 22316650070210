import React, { useState, useEffect } from "react";
import "../styles/allorderdetails.css";
import { BASE_URL } from '../api/config';
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../api/api_url";
import ReactPaginate from "react-paginate";
import "../styles/Pagination/pagination.css";

const AllOrderDetails = () => {
  const navigate = useNavigate(); 
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(null);
  const [orderStatus, setOrderStatus] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); 
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch the order status from localStorage on load
  useEffect(() => {
    const storedOrderStatus = localStorage.getItem("BookStatus") || "All";
    setOrderStatus(storedOrderStatus);
  }, []);
  
  // Function to handle status changes and store them in localStorage
  const handleOrderStatusChange = (event) => {
    const selectedCategory = event.target.value;
    setOrderStatus(selectedCategory);
    localStorage.setItem("BookStatus", selectedCategory);
  };

  // Fetch all orders from the backend
  useEffect(() => {
    const fetchOrders = async () => {
      const accessToken = localStorage.getItem('accessToken');
      try {
        const response = await fetch(`${BASE_URL}${apiUrl.ALL_BOOKING_DETAILS}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setOrders(data);
        setFilteredOrders(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  // Filter orders based on the selected status and search term
  useEffect(() => {
    let filteredData = orders;

    // Apply status filtering
    if (orderStatus !== "All") {
      switch(orderStatus) {
        case "Pending":
          filteredData = orders.filter(order => order.verify === "0");
          break;
        case "Accepted":
          filteredData = orders.filter(order => order.verify === "200");
          break;
        case "Finished":
          filteredData = orders.filter(order => order.verify === "300");
          break;
        case "Canceled":
          filteredData = orders.filter(order => order.verify === "404");
          break;
        default:
          filteredData = orders;
      }
    }

    setFilteredOrders(filteredData);
    setCurrentPage(1);
  }, [orderStatus, orders]);


  const handleSearch = () => {
    if (!searchTerm.trim()) {
      setFilteredOrders(orders); // Reset if the search is empty
      return;
    }
    const filteredData = orders.filter((order) =>
      order.BookingID.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );
    setFilteredOrders(filteredData);
    setCurrentPage(1);
  };

  // Format date utility function
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    
    // Format date as "Mar 21, 2025"
    const formattedDate = date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short',  // "short" gives abbreviated month (Mar, Apr, etc.)
      day: 'numeric'   // Removes leading zero for day
    });
  
    // Format time as "11.30 AM"
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).replace(':', '.'); // Replace colon with dot
  
    return `${formattedDate} ${formattedTime}`;
  };

  // Handle view booking details
  const handleView = (bookorder) => {
    localStorage.setItem("BookStatus", orderStatus);
    navigate('/layout/view_booking_details', { state: { bookorder } });
  };

  // Pagination logic
  const indexOfLastDealer = currentPage * itemsPerPage;
  const indexOfFirstDealer = indexOfLastDealer - itemsPerPage;
  const currentfilteredOrders = filteredOrders.slice(indexOfFirstDealer, indexOfLastDealer);
  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); 
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
 // if (loading) return <center><div className="waitingspinner"></div></center>;
  // if (error) return <p>Error loading orders: {error.message}</p>;

  return (
    <div className="sell__car">
      <div className="sell__car-wrapper">
        <div className="offer__wrapper">
          <div className="offer__top">
            <h2 className="sell__car-title">All Booking History</h2>

            <div className="filter__widget-01">
            <div className="search_div">
              <input
                type="text"
                className="bookingsearch-box"
                placeholder="Search Booking ID..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="bookingsearch-btn" onClick={handleSearch}>
                Search
              </button>
            </div>

              <select value={orderStatus} onChange={handleOrderStatusChange}>
                <option value="All">All Orders</option>
                <option value="Pending">Pending Orders</option>
                <option value="Accepted">Accepted Orders</option>
                <option value="Canceled">Canceled Orders</option>
                <option value="Finished">Finished Orders</option>
              </select>
            </div>
          </div>

          <div className="offer__list">
          {
  loading ? <center><div className="waitingspinner2"></div></center>
         : (currentfilteredOrders.length > 0 ? (  
            currentfilteredOrders.map((order) => (
              <div className="offer__item" key={order.bookid}>
               <div>
              
               <img
                  src={`${BASE_URL}/${order.car_photo}`}
                  alt={`${order.car_name} pic`}
                  className="all_order__image"
                />
                 <p className="bookId_titl">
                 Booking ID : <strong>{order.BookingID}</strong>
               </p>
               </div>

                <div className="box__01">
                  <h3 className="client__name">{order.car_name}</h3>
                  <h6 className="avg__price">
                    ₹ <span>{order.price_per_km} /km</span>
                  </h6>
                  <h6 className="avg__price">
                    ₹ <span>{order.one_day_rent} /day</span>
                  </h6>
                  {/* <span className="arrow__key">
                    <i className="ri-arrow-right-line"></i>
                  </span> */}

                  <p className="spend__title">{order.kms} Kms</p>
                </div>

                

                <div className="box__03">
                  <span className="model__spend-icon">
                    <i className="ri-car-line"></i>
                  </span>
                  <h6 className="spend__place">⮞ {order.start_place}</h6>
                  <h6 className="spend__place">⮜ {order.end_place}</h6>
                  <p className="spend__title">Booking place</p>
                </div>

                <div className="box__04">
                  <span className="model__spend-icon">
                    <i className="ri-share-forward-line"></i>
                  </span>
                  <h6 className="spend__place">{formatDateTime(order.start_date)}</h6>
                  <h6 className="spend__place">{formatDateTime(order.end_date)}</h6>
                  <p className="spend__title">Booking Date</p>
                </div>

              
                <div className="box__02">
  <span className="model__spend-icon">
    <i className="ri-wallet-3-line"></i> {/* More appropriate for amount */}
  </span>
  <h6 className="spend__amt">₹ {parseFloat(order.amt) % 1 === 0 ? parseInt(order.amt) : order.amt}/-</h6> 
  <p className="spend__title">Booking Amount</p>
</div>
              


                <div className="box__05">
                  <span className="model__spend-icon" style={{ color: getIconColor(order.verify) }}>
                    {order.verify === "0" ? (
                      <i className="ri-time-line"></i>
                    ) : order.verify === "200" ? (
                      <i className="ri-check-line"></i>
                    ) : order.verify === "300" ? (
                      <i className="ri-trophy-line"></i>
                    ) : order.verify === "404" ? (
                      <i className="ri-close-circle-line"></i>
                    ) : (
                      <i className="ri-error-warning-line"></i>
                    )}
                  </span>
                  <span className="arrow__key2" onClick={() => handleView(order)}>
                    View &nbsp;<i className="ri-arrow-right-line"></i>
                  </span>
                  <h6 className="spend__place" style={{ color: getIconColor(order.verify) }}>
                    {order.verify === "0" ? 'Pending' : 
                    order.verify === "200" ? 'Accepted' : 
                    order.verify === "300" ? 'Finished' : 
                    order.verify === "404" ? 'Canceled' : 'Unknown'}
                  </h6>
                  <p className="spend__title">Booking Status</p>
                 
                </div>

              </div>
            ))
          ) : (
            <center className='vehno--data'><p>No Bookings found...!</p></center>
          ))
          
          }
          </div>


{/* Pagination Controls */}
  {/* Pagination */}
  {totalPages > 1 && (
        <ReactPaginate
          previousLabel={"« Previous"}
          nextLabel={"Next »"}
          breakLabel={"..."}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          disabledClassName={"disabled"}
          previousClassName={"prev"}
          nextClassName={"next"}
        />
      )}



        </div>
      </div>
    </div>
  );
};

// Function to get the appropriate color for booking status icons
const getIconColor = (verifyStatus) => {
  switch (verifyStatus) {
    case "0":
      return "orange"; // Pending
    case "200":
      return "green"; // Accepted
    case "300":
      return "#725cff"; // Finished
    case "404":
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};

export default AllOrderDetails;
