import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../api/config'; 
import "../styles/dealerdtls.css";
import { useNavigate } from 'react-router-dom';
import ImagePopup from '../components/reuseable/ImagePopup';
import DealerForm from '../components/Form/DealerForm';
import ConfirmationModal from "../components/Model/ConfirmationModal"; // Import your modal component
import { apiUrl } from '../api/api_url';
import ReactPaginate from "react-paginate";
import "../styles/Pagination/pagination.css";

const DealerDtls = () => {
  const navigate = useNavigate(); 
  const [Dealer, setDealer] = useState([]);
  const [unverifyDealer, setunverifyDealer] = useState([]);
  const [dealerRequests, setDealerRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(null);
  const [dealerStatus, setDealerStatus] = useState("active");
  const [searchTerm, setSearchTerm] = useState('');
  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');
  const [dealerFormOpen, setDealerFormOpen] = useState(false);
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [isModalOpen, setModalOpen] = useState(false);

  const [dealerAcess, setDealerAddAccess] = useState(false);

  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setImagePopupOpen(true);
  };

  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };


  useEffect(() => {
    const staffAccess = JSON.parse(localStorage.getItem("staffaccess") || "{}");
    const whoislog = localStorage.getItem("whoislog");
    const accessCategory = staffAccess.access_category
    ? JSON.parse(staffAccess.access_category)
    : {};
   
    if ((staffAccess && accessCategory?.["Dealer"]?.Add) || whoislog === 'Admin') {
      setDealerAddAccess(true);
    } else {
      setDealerAddAccess(false);
    }
    
    const storedDealerStatus = localStorage.getItem("delrStatus") || "active";
    setDealerStatus(storedDealerStatus);
  }, []); // Run only once on initial render

  // Fetch dealers whenever the dealerStatus changes
  useEffect(() => {
      fetchunverifyDealers(dealerStatus);
      fetchDealers(dealerStatus);
      setCurrentPage(1);
  }, [dealerStatus]);

  // Fetch dealers from the server
  const fetchDealers = async (status) => {
    setLoading(true);
    setError(null);
    try {
      console.log("status", status);
      const accessToken = localStorage.getItem("accessToken");
      let endpoint =
       `${apiUrl.GET_ALL_DEALERS}`;

      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch dealer data.");
      }

      const data = await response.json();
      setDealer(data); // Store the full dealer data
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const fetchunverifyDealers = async (status) => {
    setLoading(true);
    setError(null);
    try {
      console.log("status", status);
      const accessToken = localStorage.getItem("accessToken");
      let endpoint =
       `${apiUrl.GET_UNVERIFY_DEALERS}` ;

      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch dealer data.");
      }

      const data = await response.json();
      setunverifyDealer(data); // Store the full dealer data
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };



  // Filter dealers based on status and search term
  useEffect(() => {
    if (Dealer) {
      let filtered = Dealer;

      if(dealerStatus === "all") {
        filtered = Dealer;
      }else if (dealerStatus === "active") {
        filtered = filtered.filter((dlr) => dlr.Ad_Verify === 1);
      } else if (dealerStatus === "deactive") {
        filtered = filtered.filter((dlr) => dlr.Ad_Verify === 2);
      } else if (dealerStatus === "pending") {
        filtered = filtered.filter((dlr) => dlr.Ad_Verify === 0);
      } else if (dealerStatus === "unverify") {
        // You do not need to call fetchDealers again, filtering is handled here
        filtered = unverifyDealer.filter((dlr) => dlr.verified === 0 && dlr.Ad_Verify === 1);
      }

      // Apply search filtering
      if (searchTerm) {
        filtered = filtered.filter(
          (dealer) =>
            dealer.usr_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            dealer.usr_num.includes(searchTerm)
        );
      }

      setDealerRequests(filtered); // Update the filtered list
      console.log("dealerStatus", dealerStatus);
      console.log("filtered", filtered);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Dealer, dealerStatus, searchTerm]); // Runs when dealer data, status or search term changes

  // Handle dealer status change
  const handleDealerStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setDealerStatus(selectedStatus);
    localStorage.setItem("delrStatus", selectedStatus);
  };

  // Handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  // Handle view dealer action
  const handleView = (dealer) => {
    localStorage.setItem("delrStatus", dealerStatus);
    navigate("/layout/view_dealer_details", { state: { dealer } });
  };

  // Pagination logic
  const indexOfLastDealer = currentPage * itemsPerPage;
  const indexOfFirstDealer = indexOfLastDealer - itemsPerPage;
  const currentDealers = dealerRequests.slice(indexOfFirstDealer, indexOfLastDealer);
  const totalPages = Math.ceil(dealerRequests.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // `react-paginate` uses 0-based index
  };

  const [selectedDel, setSelectedDel] = useState(null);
  const openModal = (DelId) => {
    setSelectedDel(DelId);
    setModalOpen(true);
  };



  const handleConfirm = async () => {
    try {

    const dealerId = selectedDel['id']; 
    const accessToken = localStorage.getItem("accessToken");
      // Send DELETE request to backend
      const response = await fetch(`${BASE_URL}${apiUrl.DELETE_DEALERS}/${dealerId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`, 
        },
      });
  
      if (response.ok) {
       alert('Dealer deleted successfully');
      } else {
        alert('Failed to delete dealer');
      }
    } catch (error) {
      console.error('Error deleting dealer:', error);
    }
    fetchunverifyDealers(dealerStatus);
    setModalOpen(false);
    window.location.reload();
  };
  


  //if (loading) return <center><div className="waitingspinner"></div></center>;
  // if (error) return <p>Error: {error}</p>;

  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="dealerdtls-requests">
          <div className='topflex'>
            <h2 className="dealerdtls-requests__heading">Dealer Details</h2>
            <div className="search__box">
              <input
                type="text"
                placeholder="Search by name or phone number"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span>
                <i className="ri-search-line"></i>
              </span>
            </div>

    {dealerAcess&& (
    <button className="add-dealer-button" onClick={() => setDealerFormOpen(true)}>
        <span className="button-text">Add Dealer</span>
        <span className="button-icon">
            <i className="ri-add-line"></i>
        </span>
    </button>
)}



            <div className="filter__widget-01">
              <select value={dealerStatus} onChange={handleDealerStatusChange}>
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="deactive">Deactive</option>
                <option value="pending">Pending</option>
                <option value="unverify">Unverified</option>
              </select> 
            </div>   
          </div>
          <table className="dealerdtls-requests__table">
            <thead className="dealerdtls-requests__thead">
              <tr className="dealerdtls-requests__thead-row">
                <th className="dealerdtls-requests__thead-cell">ID</th>
                <th className="dealerdtls-requests__thead-cell">Profile</th>
                <th className="dealerdtls-requests__thead-cell">Dealer Name</th>
                <th className="dealerdtls-requests__thead-cell">Phone Number</th>
                <th className="dealerdtls-requests__thead-cell">Address</th>
                <th className="dealerdtls-requests__thead-cell">Pincode</th>
                <th className="dealerdtls-requests__thead-cell">Work Status</th>
                <th className="dealerdtls-requests__thead-cell">Id proof</th>
                <th className="dealerdtls-requests__thead-cell">Status</th>
                <th className="dealerdtls-requests__thead-cell">Actions</th>
              </tr>
            </thead>
            <tbody className="dealerdtls-requests__tbody">
              {currentDealers.map((dealer,index) => (
                <tr key={dealer.id} className="dealerdtls-requests__tbody-row">
                  <td className="dealerdtls-requests__tbody-cell">{indexOfFirstDealer+index+1}</td>
                  <td className="dealerdtls-requests__tbody-cell">
                  
                     <img
  src={dealer.usr_profile ? `${BASE_URL}/${dealer.usr_profile}` : require('../assets/images/profile-02.png')}
  alt={`${dealer.usr_name} Profile`}
   className="dealerdtls-requests__profile-image"
  onClick={() => dealer.usr_profile && openImagePopup(`${BASE_URL}/${dealer.usr_profile}`)}
/>
                  </td>
                  <td className="dealerdtls-requests__tbody-cell">{dealer.usr_name}</td>
                  <td className="dealerdtls-requests__tbody-cell">{dealer.usr_num}</td>
                  <td className="dealerdtls-requests__tbody-cell">{dealer.address}</td>
                  <td className="dealerdtls-requests__tbody-cell">{dealer.pincode}</td>
                  <td className="dealerdtls-requests__tbody-cell">{dealer.auth_sts}</td>
                  <td className="dealerdtls-requests__tbody-cell">

                        <img
                          src={dealer.id_proof && dealer.id_proof.trim() !== "" ? `${BASE_URL}/${dealer.id_proof}` : require('../assets/images/no_img.jpg')}
                          alt={`${dealer.usr_name} Document`}
                          className="dealerdtls-requests__proof-image"
                          onClick={() => dealer.id_proof && dealer.id_proof.trim() !== "" && openImagePopup(`${BASE_URL}/${dealer.id_proof}`)}
                        />

                  </td>
                  <td 
  className="dealerdtls-requests__tbody-cell"
  style={{
    color: dealer.verified === 0 
  ? "grey" :dealer.Ad_Verify === 0 ? "orange" 
         : dealer.Ad_Verify === 1 ? "green" 
         : "red"
  }}
>
  {
  dealer.verified === 0 
  ?"Unverified"
  :dealer.Ad_Verify === 0
    ? "Pending" 
    : dealer.Ad_Verify === 1 
    ? "Active" 
    : "Deactive"}
</td>

                  <td className="dealerdtls-requests__tbody-cell">
                    <button className="dealerdtls-requests__action-btn view-btn" onClick={() => handleView(dealer)}>view</button>
                   
      {dealerStatus && dealerStatus === "unverify" && (  
                    <i className="ri-delete-bin-fill  dlrbinicon"  onClick={() =>  openModal(dealer)}></i>
      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}

         {/* Pagination */}
        {totalPages > 1 && (
        <ReactPaginate
          previousLabel={"« Previous"}
          nextLabel={"Next »"}
          breakLabel={"..."}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          disabledClassName={"disabled"}
          previousClassName={"prev"}
          nextClassName={"next"}
        />
      )}

{
loading?<center><div className="waitingspinner2"></div></center>
:(currentDealers.length < 1 && (
   
    <center className='dlrno--data'><p>No dealers found...!</p></center>

))}
        
          {imagePopupOpen && (
            <ImagePopup 
              src={popupImageSrc} 
              alt="Vehicle" 
              onClose={closeImagePopup} 
            />
          )}

{/* Dealer Registration Form Popup */}
{dealerFormOpen && (
  <div className="dealer-form-overlay">
    <DealerForm 
       onSubmitSuccess={() => {
        setDealerFormOpen(false); 
         window.location.reload();
        setDealerStatus("unverify");
      }}
      onClose={() => {
        setDealerFormOpen(false); 
      }} 
   
    />
  </div>
)}


<ConfirmationModal 
        isOpen={isModalOpen} 
        onClose={() => setModalOpen(false)} 
        onConfirm={handleConfirm} 
        message={`Are you sure you want to delete this Dealer?`} 
      />


        </div>
      </div>
    </div>
  );
};

export default DealerDtls;
