import React, { useState, useEffect } from "react";
import serv1 from "../assets/image/serv1.jpeg";
import serv2 from "../assets/image/serv2.jpg";
import serv3 from "../assets/image/serv3.jpeg";

const ServiceCard = ({ title, description, image, isActive }) => {
  return (
    <div className={`service-card ${isActive ? "active" : ""}`}>
      <img src={image} alt={title} className="service-card__image" />
      <h3 className="service-card__title">{title}</h3>
      <p className="service-card__description">{description}</p>
    </div>
  );
};

const OurServices = () => {
  const services = [
    {
      title: "Rental Cars",
      description:
        "Choose from a wide range of rental cars for your travel needs, ensuring comfort and flexibility.",
      image: serv1,
    },
    {
      title: "Online Bookings",
      description:
        "Book your rides online effortlessly with our user-friendly booking platform.",
      image: serv2,
    },
    {
      title: "Long Trips",
      description:
        "Plan your long-distance journeys with ease and enjoy a smooth, hassle-free travel experience.",
      image: serv3,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % services.length);
    }, 3000); // Change highlight every 3 seconds

    return () => clearInterval(interval);
  });

  return (
    <section className="our-services">
      <h4 className="our-services__subtitle">OUR SERVICE</h4>
      <h2 className="our-services__title" data-aos="fade-right">
        Best Taxi Service <br /> For Your Town
      </h2>
      <center>
        <p className="our-services__description" data-aos="fade-right">
          Experience reliable and comfortable taxi services tailored for your
          town. Our professional drivers and well-maintained vehicles ensure
          safe, affordable, and timely transportation, making your journeys
          smooth and hassle-free.
        </p>
      </center>
      <div className="our-services__cards" data-aos="fade-up">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} isActive={index === activeIndex} />
        ))}
      </div>
    </section>
  );
};

export default OurServices;
