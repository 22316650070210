import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../api/config'; 
import "../styles/dealerdtls.css";
import { useNavigate } from 'react-router-dom';
import ImagePopup from '../components/reuseable/ImagePopup';
import { apiUrl } from '../api/api_url';
import ReactPaginate from "react-paginate";
import "../styles/Pagination/pagination.css";

const ClientDtls = () => {
  const navigate = useNavigate(); 
  const [Client, setClient] = useState([]);
  const [clientRequests, setClientRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [clientStatus, setClientStatus] = useState("active");
  const [searchTerm, setSearchTerm] = useState('');
  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items per page
  
    useEffect(() => {
      const storedDealerStatus = localStorage.getItem("clientStatus") || "active";
      setClientStatus(storedDealerStatus); // Set the saved order status or default to "All"
    }, []);

  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setImagePopupOpen(true);
  };

  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };

  const handleClientStatusChange = (event) => {
    const selectedCategory = event.target.value;
    setClientStatus(selectedCategory);
    localStorage.setItem("clientStatus", selectedCategory);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);  
    setCurrentPage(1);
    };

  // Fetch dealer requests from backend
  useEffect(() => {
    const fetchClientRequests = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken"); // Fetch the token from localStorage
        const response = await fetch(`${BASE_URL}${apiUrl.GET_CLIENT_DETAILS}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`, // Ensure your request is authenticated
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch Client requests');
        }

        const data = await response.json();
        setClient(data)
        setClientRequests(data); // Set fetched data to the state
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClientRequests();
  }, []);


  useEffect(() => {
    let filteredData = Client;

    // Filter by car type
    // if (carType !== "All") {
    //   filteredData = filteredData.filter((order) => order.car_type === carType);
    // }

    // Filter by order status

    if(clientStatus === "all"){
      filteredData = Client;
    }else if (clientStatus === "active") {
        filteredData = filteredData.filter((dlr) => (dlr.Ad_Verify === 0 || dlr.Ad_Verify === 1));
      } else if (clientStatus === "deactive") {
        filteredData = filteredData.filter((dlr) => dlr.Ad_Verify === 2);
      }

      if (searchTerm) {
        filteredData = filteredData.filter((dealer) => 
          dealer.usr_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
          dealer.usr_num.includes(searchTerm)
        );
      }

      setClientRequests(filteredData);
  }, [clientStatus, Client,searchTerm]);


  // Function to handle Accept action
  const handleView = (client) => {
    localStorage.setItem("clientStatus", clientStatus);
    navigate('/layout/view_client_details', { state: { client } });
    console.log('next page view_dealer_details');
  };

  const indexOfLastClient = currentPage * itemsPerPage;
  const indexOfFirstClient = indexOfLastClient - itemsPerPage;
  const currentClient = clientRequests.slice(indexOfFirstClient, indexOfLastClient);
  const totalPages = Math.ceil(clientRequests.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // `react-paginate` uses 0-based index
  };

  // if (loading) return <center><div className="waitingspinner"></div></center>;
  // if (error) return <p>Error: {error}</p>;

  return (

        <div className="dashboard">
          <div className="dashboard__wrapper">
          <div className="dealerdtls-requests">

 
  <div className='topflex'>
  <h2 className="dealerdtls-requests__heading">Client Details</h2>
    <div className="search__box">
              <input
                type="text"
                placeholder="Search by name or phone number"
                value={searchTerm}  // Controlled input
                onChange={handleSearchChange}  // Update search term on input
              />
          <span>
            <i class="ri-search-line"></i>
          </span>
        </div>
        <div className="filter__widget-01">
    <select value={clientStatus} onChange={handleClientStatusChange}>
      <option value="all">All</option>
      <option value="active">Active</option>
      <option value="deactive">Deactive</option>
    </select> 
    </div>    
    </div>
  <table className="dealerdtls-requests__table">
    <thead className="dealerdtls-requests__thead">
      <tr className="dealerdtls-requests__thead-row">
        <th className="dealerdtls-requests__thead-cell">ID</th>
        <th className="dealerdtls-requests__thead-cell">Profile</th>
        <th className="dealerdtls-requests__thead-cell">Client Name</th>
        <th className="dealerdtls-requests__thead-cell">Phone Number</th>
        {/* <th className="dealerdtls-requests__thead-cell">Address</th>
        <th className="dealerdtls-requests__thead-cell">Pincode</th>
        <th className="dealerdtls-requests__thead-cell">Auth Status</th>
        <th className="dealerdtls-requests__thead-cell">Id proof</th> */}
        <th className="dealerdtls-requests__thead-cell">Status</th>
        <th className="dealerdtls-requests__thead-cell">Actions</th>
      </tr>
    </thead>
    <tbody className="dealerdtls-requests__tbody">
      {currentClient.map((client , index) => (
        <tr key={client.id} className="dealerdtls-requests__tbody-row">
          <td className="dealerdtls-requests__tbody-cell">{indexOfFirstClient + index + 1}</td>
          <td className="dealerdtls-requests__tbody-cell">
           
<img
  src={client.usr_profile ? `${BASE_URL}/${client.usr_profile}` : require('../assets/images/profile-02.png')}
  alt={`${client.usr_name} Profile`}
   className="dealerdtls-requests__profile-image"
  onClick={() => client.usr_profile && openImagePopup(`${BASE_URL}/${client.usr_profile}`)}
/>


          </td>
          <td className="dealerdtls-requests__tbody-cell">{client.usr_name}</td>
          <td className="dealerdtls-requests__tbody-cell">{client.usr_num}</td>
          {/* <td className="dealerdtls-requests__tbody-cell">{client.address}</td>
          <td className="dealerdtls-requests__tbody-cell">{client.pincode}</td>
          <td className="dealerdtls-requests__tbody-cell">{client.auth_sts}</td>
          <td className="dealerdtls-requests__tbody-cell">
          <img
               src={`${BASE_URL}/${client.id_proof}` }
              alt={`${client.usr_name} Document`}
              className="dealerdtls-requests__proof-image"
            />
          </td> */}

<td 
  className="dealerdtls-requests__tbody-cell"
  style={{
    color: client.verified === 0 
  ? "grey" :client.Ad_Verify === 2 ? "red" 
         :  "green" 
         
  }}
>
  {
  client.verified === 0 
  ?"Unverified"
  :client.Ad_Verify === 2
    ? "Deactive" 
    : "Active" 
    }
</td>

          <td className="dealerdtls-requests__tbody-cell">
            <button className="dealerdtls-requests__action-btn view-btn" onClick={() => handleView(client)}>view</button>

          </td>
        </tr>
      ))}
    </tbody>
  </table>


  {/* Pagination Controls */}
  {/* Pagination */}
  {totalPages > 1 && (
        <ReactPaginate
          previousLabel={"« Previous"}
          nextLabel={"Next »"}
          breakLabel={"..."}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          disabledClassName={"disabled"}
          previousClassName={"prev"}
          nextClassName={"next"}
        />
      )}

{loading?<center><div className="waitingspinner2"></div></center>
:(clientRequests.length < 1 && (
   <center className='dlrno--data'><p>No client found...!</p></center>
))}

  {imagePopupOpen && (
  <ImagePopup 
    src={popupImageSrc} 
    alt="Vehicle" 
    onClose={closeImagePopup} 
  />
)}
</div>
          </div>
          
        </div>
 
  );
};

export default ClientDtls;

