import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../api/config'; 
import "../styles/dealerdtls.css";
import { apiUrl } from '../api/api_url';
import ReactPaginate from "react-paginate";
import "../styles/Pagination/pagination.css";

const WebContacts = () => {
  const [Webcontact, setWebcontact] = useState([]);
  const [ContactFilter, setContactFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
 

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [itemsPerPage, setItemsPerPage] = useState(10);
  

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);  
    setCurrentPage(1);
    };

  // Fetch dealer requests from backend
  useEffect(() => {
    const fetchClientRequests = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(`${BASE_URL}${apiUrl.GET_WEBSITE_CONTACT}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`, 
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch Client requests');
        }

        const data = await response.json();
        setWebcontact(data)
        setContactFilter(data); // Set fetched data to the state
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClientRequests();
  }, []);


  useEffect(() => {
    if (searchTerm) {
      const filteredData = Webcontact.filter((contact) => {
        const name = contact.name?.toLowerCase() || ''; // Handle undefined name
        const phone = String(contact.phone || ''); // Convert phone to string and handle undefined/null
        return (
          name.includes(searchTerm.toLowerCase()) ||
          phone.includes(searchTerm)
        );
      });
      setContactFilter(filteredData);
    } else {
      setContactFilter(Webcontact); // Reset to full list if no search term
    }
  }, [Webcontact, searchTerm]);
  




  const indexOfLastClient = currentPage * itemsPerPage;
  const indexOfFirstClient = indexOfLastClient - itemsPerPage;
  const currentClient = ContactFilter.slice(indexOfFirstClient, indexOfLastClient);
  const totalPages = Math.ceil(ContactFilter.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // `react-paginate` uses 0-based index
  };

  // if (loading) return <center><div className="waitingspinner"></div></center>;
  // if (error) return <p>Error: {error}</p>;

  return (

        <div className="dashboard">
          <div className="dashboard__wrapper">
          <div className="dealerdtls-requests">

 
  <div className='topflex'>
  <h2 className="dealerdtls-requests__heading">Customer Contactus</h2>
    <div className="search__box">
              <input
                type="text"
                placeholder="Search by name or phone number"
                value={searchTerm}  // Controlled input
                onChange={handleSearchChange}  // Update search term on input
              />
          <span>
            <i class="ri-search-line"></i>
          </span>
        </div>
    </div>
  <table className="dealerdtls-requests__table">
    <thead className="dealerdtls-requests__thead">
      <tr className="dealerdtls-requests__thead-row">
        <th className="dealerdtls-requests__thead-cell">ID</th>
        <th className="dealerdtls-requests__thead-cell">Name</th>
        <th className="dealerdtls-requests__thead-cell">Email</th>
        <th className="dealerdtls-requests__thead-cell">Phone Number</th>
        <th className="dealerdtls-requests__thead-cell">Message</th>
      </tr>
    </thead>
    <tbody className="dealerdtls-requests__tbody">
      {currentClient.map((contactus , index) => (
        <tr key={contactus.id} className="dealerdtls-requests__tbody-row">
          <td className="dealerdtls-requests__tbody-cell">{indexOfFirstClient + index + 1}</td>
          <td className="dealerdtls-requests__tbody-cell">{contactus.name}</td>
          <td className="dealerdtls-requests__tbody-cell">{contactus.email}</td>
          <td className="dealerdtls-requests__tbody-cell">{contactus.phone}</td>
          <td className="dealerdtls-requests__tbody-cell">{contactus.contant}</td>
        </tr>
      ))}
    </tbody>
  </table>


  {/* Pagination Controls */}
    {/* Pagination */}
    {totalPages > 1 && (
        <ReactPaginate
          previousLabel={"« Previous"}
          nextLabel={"Next »"}
          breakLabel={"..."}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          disabledClassName={"disabled"}
          previousClassName={"prev"}
          nextClassName={"next"}
        />
      )}

{loading?<center><div className="waitingspinner2"></div></center>
:(ContactFilter.length < 1 && (
   <center className='dlrno--data'><p>No Customers found...!</p></center>
))}


</div>
          </div>
          
        </div>
 
  );
};

export default WebContacts;

