/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { BASE_URL } from '../../api/config';
import {apiUrl} from '../../api/api_url';
import './login.css';
import { useNavigate } from 'react-router-dom';
import loginImg from "../../assets/image/taxng2.png";
import usericon from "../../assets/images/user.png";
import passicon from "../../assets/images/pass.png";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {

const [showPassword, setShowPassword] = useState(false);


 useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out',
    });
    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    usernameInputRef.current?.focus(); // Focus on the username input on page load
  }, []);

    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const usernameInputRef = useRef(null); 
    const passwordInputRef = useRef(null); 

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        const LogNumber = localStorage.getItem("Number");
    
         console.log('accessToken',accessToken,'LogNumber',LogNumber);
        
        if (accessToken || LogNumber) {
            navigate('/layout/dashboard');
        }
    
      }, [navigate]);


    const handleLogin = async () => {
        if (!username || !password) {
            Swal.fire({
                title: 'Error!',
                text: 'Please enter both username and password.',
                icon: 'error',
                timer: 2000,
            });
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(`${BASE_URL}${apiUrl.STAFF_LOGIN_AUTH}`, {
                username,
                password,
            });

            if (response.status === 200) {
                const { accessToken, userDetails } = response.data;

                // Store credentials in session or local storage
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('userDetails', JSON.stringify(userDetails));
                localStorage.setItem('whoislog',userDetails.staff_role);
                localStorage.setItem('Number', userDetails.staff_num);
                
                if(userDetails && userDetails.staff_access != null &&userDetails.staff_access.active_access === '1'){
                    localStorage.setItem('staffaccess', JSON.stringify(userDetails.staff_access));
                    console.log(userDetails.staff_access.active_access,'True');
                }

                console.log(accessToken,userDetails);
                console.log('userDetails.staff_access',userDetails.staff_access);

                Swal.fire({
                    title: 'Success!',
                    text: 'Login successful.',
                    icon: 'success',
                    timer: 1000,
                    showConfirmButton: false,
                }).then(() => {
                    navigate('/layout/dashboard');
                });
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: response.data.message || 'Invalid credentials.',
                    icon: 'error',
                    timer: 2000,
                });
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Login failed. Please try again.',
                icon: 'error',
                timer: 2000,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="Loginpage">
            
            <div className='logdiv'>
                
            <div className="logincontainer"data-aos="fade-right">
                <div className="imgseataxi"></div>
                <h2 className="title">Login</h2>

                <div className="formGroup">
                    <label htmlFor="username" className="loglabel">
                        Username
                    </label>
                    <div className="input-wrapper">
                    <img
   src={usericon} 
            className="lableimg" 
            />
                    <input
                        type="text"
                        id="username"
                        placeholder="Enter your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="loginput"
                        ref={usernameInputRef}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              passwordInputRef.current?.focus(); // Focus the password input
                            }
                          }}
                    />
                </div>
                </div>

                <div className="formGroup">
  <label htmlFor="password" className="loglabel">
    Password
  </label>
  <div className="input-wrapper">
    <img src={passicon} alt="Password Icon" className="lableimg" />
    
    <input
      type={showPassword ? "text" : "password"} // Toggle between text & password
      id="password"
      placeholder="Enter your password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      className="loginput"
      ref={passwordInputRef}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleLogin();
        }
      }}
    />

    <span
      onClick={() => setShowPassword(!showPassword)}
      style={{
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        color: "#888",
      }}
    >
      {showPassword ? <FaEye size={18} /> : <FaEyeSlash size={18} />}
    </span>
  </div>
</div>


                <button className="logbutton" onClick={handleLogin}>
                    {loading ? (
                        <div className="logspinner"></div> // Show spinner when loading
                    ) : (
                        'Login'
                    )}
                </button>
            </div>
            </div>

                      {/* Left container for the image */}
      {/* <div className="login-image-container">
      <img loading="lazy" src={loginImg} alt="Login Illustration" className="login-image" />
      </div> */}
<div className="login-image-container" >
      <div className="login-content" data-aos="fade-left">
      <h1>Welcome Back to Sea Taxi!</h1>
<p>
  Join us on a journey across the waves. Log in to book your ride, manage bookings, and explore the best sea travel experience.
</p>

      
      </div>
      <div className="image-section"data-aos="fade-left">
        <img
          src={loginImg}
          alt="Person walking dog"
          className="login-image"
        />
      </div>
    </div>
            
        </div>
    );
};

export default Login;
