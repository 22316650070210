import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../assets/css/Dealer.css';
import { FaCar, FaMoneyBillWave, FaChartLine, FaClipboardList } from "react-icons/fa";
import del_sc from "../assets/image/del_sc.png";
import app_sor from "../assets/image/google-play-and-apple-app-store-logos-22-removebg-preview.png";


const Dealer = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section id="DEALER" className="dealerContainer">
        <center>
          <div className='Dealer_Main_Div'>

            <div className='flexDealer'>
              <div className="dealer-content" data-aos="fade-right">
                <h1>
                  Join SeaTaxi as a Dealer <br />

                </h1>
                <span className="highlight">Earn more, drive smart!</span>
                <p>Get more ride requests and maximize your earnings with SeaTaxi.</p>
                <div className='home_taxi_app_download_1' data-aos="zoom-in">
                  <a href="https://play.google.com/store/apps/details?id=com.seataxidealer.seasense&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                    <img src={app_sor} alt="Download SeaTaxi Driver App" />
                  </a>
                </div>

              </div>
              <div className="dealer-image" data-aos="fade-left">
                <div className="yellow-background"></div>
                <img src={del_sc} alt="Bike Taxi" />
              </div>
            </div>

          </div>
        </center>




        <section className="how-it-works" data-aos="fade-up">
          <h2>How SeaTaxi Dealer Works</h2>
          <div className="steps">
            <div className="step">
              <span className="step-number">1</span>
              <p>Sign up as a dealer and create your account.</p>
            </div>
            <div className="step">
              <span className="step-number">2</span>
              <p>Add your vehicles and set pricing.</p>
            </div>
            <div className="step">
              <span className="step-number">3</span>
              <p>Receive ride booking requests.</p>
            </div>
            <div className="step">
              <span className="step-number">4</span>
              <p>Assign vehicles and confirm bookings.</p>
            </div>
            <div className="step">
              <span className="step-number">5</span>
              <p>Track your earnings and ride history.</p>
            </div>
            <div className="step">
              <span className="step-number">6</span>
              <p>Receive payments directly to your account.</p>
            </div>
          </div>
        </section>

        {/* Advantages Section */}
        <section className="advantages" data-aos="fade-up">
          <h2>Advantages of SeaTaxi Dealer</h2>
          <div className="advantage-items">
            <div className="advantage">
              <FaChartLine className="icon" />
              <h3>Maximize Fleet Utilization</h3>
              <p>Ensure your vehicles are always generating revenue.</p>
            </div>
            <div className="advantage">
              <FaClipboardList className="icon" />
              <h3>Real-Time Tracking</h3>
              <p>Monitor vehicle locations and manage bookings efficiently.</p>
            </div>
            <div className="advantage">
              <FaCar className="icon" />
              <h3>Higher Earnings</h3>
              <p>Reach more customers and increase your profits.</p>
            </div>
            <div className="advantage">
              <FaMoneyBillWave className="icon" />
              <h3>Seamless Payments</h3>
              <p>Receive automated, hassle-free payments.</p>
            </div>
          </div>
        </section>










      </section>








      <Footer />
    </>
  );
};

export default Dealer;