import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../api/config';
import "../../styles/dealerdtls.css";
import "./HistoryDetails.css";
import Swal from 'sweetalert2';
import ImagePopup from '../../components/reuseable/ImagePopup';
import { apiUrl } from '../../api/api_url';
import ConfirmationModal from "../../components/Model/ConfirmationModal";
import ReactPaginate from "react-paginate";
import "../../styles/Pagination/pagination.css";

const HistoryDetails = () => {

  const [WorkingHistory, setWorkingHistory] = useState([]);
  const [HistoryFilter, setHistoryFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterMonth, setFilterMonth] = useState('');
  const [filterYear, setFilterYear] = useState('');
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setImagePopupOpen(true);
  };

  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };


  const openModal = () => {
    setModalOpen(true);
  };



  useEffect(() => {
    const fetchClientRequests = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(`${BASE_URL}${apiUrl.GET_LOG_HISTORY}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch Client requests');
        }

        const data = await response.json();
        setWorkingHistory(data);
        setHistoryFilter(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClientRequests();
  }, []);

  useEffect(() => {
    let filteredData = WorkingHistory;

    if (searchTerm) {
      filteredData = filteredData.filter((history) => {
        const staff_role = history.staff_role?.toLowerCase() || '';
        const category = String(history.category || '');
        return (
          staff_role.includes(searchTerm.toLowerCase()) ||
          category.includes(searchTerm)
        );
      });
    }

    if (filterDate) {
      filteredData = filteredData.filter(history => {
        // Format the datetime to 'yyyy-MM-dd' for comparison
        const historyDate = new Date(history.datetime).toISOString().split('T')[0];
        return historyDate === filterDate; // Compare with filterDate (assumed in 'yyyy-MM-dd')
      });
    }

    if (filterMonth) {
      filteredData = filteredData.filter(history => {
        const historyMonth = new Date(history.datetime).getUTCMonth() + 1; // Months are 0-based
        return String(historyMonth) === String(filterMonth); // Compare as strings for consistency
      });
    }

    if (filterYear) {
      filteredData = filteredData.filter(history => {
        const historyYear = new Date(history.datetime).getUTCFullYear();
        return String(historyYear) === String(filterYear); // Compare as strings for consistency
      });
    }


    if (selectedAction) {
      filteredData = filteredData.filter(history => history.action === selectedAction);
    }

    if (selectedCategory) {
      filteredData = filteredData.filter(history => history.category === selectedCategory);
    }

    setHistoryFilter(filteredData);
    setCurrentPage(1);
  }, [WorkingHistory, searchTerm, filterDate, filterMonth, filterYear, selectedAction, selectedCategory]);

  const indexOfLastClient = currentPage * itemsPerPage;
  const indexOfFirstClient = indexOfLastClient - itemsPerPage;
  const currentClient = HistoryFilter.slice(indexOfFirstClient, indexOfLastClient);
  const totalPages = Math.ceil(HistoryFilter.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // `react-paginate` uses 0-based index
  };


  const [selectedItems, setSelectedItems] = useState([])
  // Handle individual checkbox
  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle "Check All" checkbox
  const handleCheckAll = () => {
    if (selectedItems.length === currentClient.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(currentClient.map((item) => item.id));
    }
  };

  // Handle delete selected items
  const handleDelete = async () => {
    const accessToken = localStorage.getItem("accessToken");
    console.log('selectedItems', selectedItems);
    setModalOpen(false);
    try {
      // Send a DELETE request to the backend
      const response = await fetch(`${BASE_URL}${apiUrl.DELETE_HISTORY}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ ids: selectedItems }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete history');
      }
      Swal.fire({
        title: 'Success!',
        text: 'History Deleted Successfully',
        icon: 'success',
        timer: 2000,
      })
      // Filter out the deleted items from the frontend
      const updatedHistory = WorkingHistory.filter(
        (item) => !selectedItems.includes(item.id)
      );

      setWorkingHistory(updatedHistory);
      setHistoryFilter(updatedHistory);
      setSelectedItems([]);
    } catch (error) {
      console.error('Error deleting history:', error);
      alert('An error occurred while deleting the items.');
    }
  };


  const [popupData, setPopupData] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleIconClick = (data) => {
    setPopupData(data); // Set the data for the popup
    setPopupVisible(true); // Show the popup
  };

  const closePopup = () => {
    setPopupData(null); // Clear the popup data
    setPopupVisible(false); // Hide the popup
  };


  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];


  // const fieldNameMapping = {
  //   staff_name: "STAFF NAME",
  //   staff_role: "STAFF ROLE",
  //   staff_num: "STAFF NUMBER",
  //   action: "ACTION",
  //   category: "CATEGORY",
  //   prev_data: "Previous Data",
  //   new_data: "New Data",
  //   delete_data: "Deleted Data",
  //   whoislogin: 'LOG STATUS',
  //   usr_profile: "USER PROFILE",
  //   usr_name: "USER NAME",
  //   usr_num: "USER NUMBER",
  //   timestamp: "DATE AND TIME",
  //   auth_sts: "WORK STATUS",
  //   travels_certify: "TRAVELS CERTIFICATE",
  //   license_path: "LICENCE IMAGE",
  //   licence_num: "LICENCE NUMBER",
  //   Ad_Verify: "ADMIN VERIFY",
  //   ad_rej_rsn: "REJECT REASON",


  //   phn: "PHONE NUMBER",
  //   types: "VEHICLE TYPE",

  //   up_id: "UPDATE ID",
  //   veh_id: "VEHICLE ID",
  //   veh_tok: "VEHICLE TOKEN",
  //   veh_del_id: "DEALER ID",
  //   del_num: "DEALER NUMBER",
  //   up_veh_img: "VEHICLE IMAGE",
  //   insur_img: "INSURANCE IMAGE",
  //   cost_km: "COST PER KILOMETERS",
  //   cost_day: "COST PER DAY",
  //   min_km: "COST FOR MINIMUM KM",
  //   max_kmday: "MAXIMUM KM PER DAY",
  //   ad_verify: "ADMIN VERIFY",
  //   up_rej_res: "REJECT REASON",

  //   dealid: 'DEALER ID',
  //   dealer_num: 'DEALER NUMBER',
  //   Taxi_posin: 'POSITION',
  //   veh_locate: 'VEHICLE LOCATE',
  //   maxkm_day: 'MAXIMUM KM PER DAY',
  //   minkm_cost: 'COST FOR MINIMUM KM',
  //   rcbook_path: 'RCBOOK IMAGE',
  //   insurance_img: 'INSURANCE IMAGE',
  //   apply_date: 'REGISTER DATE',
  //   vehAprove: 'APPROVE STATUS',
  //   veh_rej_rsn: 'VEHICLE REJECT REASON',

  // };


  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    try {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        // hour: '2-digit',
        // minute: '2-digit',
        // second: '2-digit', // uncomment if you need seconds
        hour12: true
      });
    } catch (e) {
      return 'Invalid Date';
    }
  };

  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="dealerdtls-requests">
          <div className="topflex">
            <h2 className="dealerdtls-requests__heading">Seataxi Working History</h2>
            <div className="search__box">
              <input
                type="text"
                placeholder="Search by UserRole or Category"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span>
                <i className="ri-search-line"></i>
              </span>
            </div>
          </div>

          {/* Filter Inputs */}
          <div className="history-filter-controls">
            <input
              type="date"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
              placeholder="Filter by Date"
            />
            <select value={filterMonth} onChange={(e) => setFilterMonth(e.target.value)}>
              <option value="">Select Month</option>
              {months.map((month, index) => (
                <option key={index + 1} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
            <select value={filterYear} onChange={(e) => setFilterYear(e.target.value)}>
              <option value="">Select Year</option>
              {[2024, 2025, 2026, 2027, 2028, 2029, 2030].map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
            <select
              value={selectedAction}
              onChange={(e) => setSelectedAction(e.target.value)}
            >
              <option value="">Filter by Action</option>
              {Array.from(new Set(WorkingHistory.map(item => item.action))).map((action, index) => (
                <option key={index} value={action}>{action}</option>
              ))}
            </select>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Filter by Category</option>
              {Array.from(new Set(WorkingHistory.map(item => item.category))).map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
          </div>

          {selectedItems.length > 0 && (
            <button className="Selected-delete-button" onClick={openModal}>
              Delete Selected
            </button>
          )}

          <table className="dealerdtls-requests__table">
            <thead className="dealerdtls-requests__thead">
              <tr className="dealerdtls-requests__thead-row">
                <th className="dealerdtls-requests__thead-cell">
                  <input
                    type="checkbox"
                    onChange={handleCheckAll}
                    checked={selectedItems.length === currentClient.length && currentClient.length > 0}
                  />
                </th>
                <th className="dealerdtls-requests__thead-cell">Sl.No</th>
                <th className="dealerdtls-requests__thead-cell">Name</th>
                <th className="dealerdtls-requests__thead-cell">Role</th>
                <th className="dealerdtls-requests__thead-cell">Action</th>
                <th className="dealerdtls-requests__thead-cell">Category</th>
                <th className="dealerdtls-requests__thead-cell">Date</th>
                <th className="dealerdtls-requests__thead-cell">Time</th>
                <th className="dealerdtls-requests__thead-cell">View Detail</th>
              </tr>
            </thead>
            <tbody className="dealerdtls-requests__tbody">
              {currentClient.map((history, index) => {
                const datetime = new Date(history.datetime);
                const formattedDate = datetime.toLocaleDateString('en-GB');
                const formattedTime = datetime.toLocaleTimeString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                });

                return (
                  <tr key={history.id} className="dealerdtls-requests__tbody-row">
                    <td className="dealerdtls-requests__tbody-cell">
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(history.id)}
                        onChange={() => handleCheckboxChange(history.id)}
                      />
                    </td>
                    <td className="dealerdtls-requests__tbody-cell">{indexOfFirstClient + index + 1}</td>
                    <td className="dealerdtls-requests__tbody-cell">{history.staff_name}</td>
                    <td className="dealerdtls-requests__tbody-cell">{history.staff_role}</td>
                    <td className="dealerdtls-requests__tbody-cell">{history.action}</td>
                    <td className="dealerdtls-requests__tbody-cell">{history.category}</td>
                    <td className="dealerdtls-requests__tbody-cell">{formattedDate}</td>
                    <td className="dealerdtls-requests__tbody-cell">{formattedTime}</td>
                    <td className="dealerdtls-requests__tbody-cell"><i className="ri-eye-fill icon-green" style={{ color: 'green', cursor: 'pointer' }} onClick={() => handleIconClick(history)}></i></td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Pagination */}
          {totalPages > 1 && (
            <ReactPaginate
              previousLabel={"« Previous"}
              nextLabel={"Next »"}
              breakLabel={"..."}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              disabledClassName={"disabled"}
              previousClassName={"prev"}
              nextClassName={"next"}
            />
          )}

          {loading ? (
            <center><div className="waitingspinner2"></div></center>
          ) : (HistoryFilter.length < 1 && (
            <center className='dlrno--data'><p>No action found...!</p></center>
          ))}
        </div>
      </div>


      {isPopupVisible && (
  <div className="hiscustom-popup">
    <div className="hiscustom-popup-content">
      <div className='pop_head_row'>
        <h4>Work History Details</h4>
        <span className="hiscustom-popup-close" onClick={closePopup}>&times;</span>
      </div>


      {/* New Data Table */}
     {(() => {
  const dataToShow = popupData?.category !== 'VehicleUpdate' && (popupData?.new_data || popupData?.delete_data);
  if (!dataToShow) return null;
  
  return (
    <div>
      <p><strong>{popupData.new_data ? 'NEW DATA:' : 'DELETED DATA:'}</strong></p>
      <table className="hiscustom-data-table">
        <tbody>
          {JSON.parse(dataToShow).map((item, index) => (
                <>
                 {popupData.category === 'Staff' && (
                      <>
                        <tr>
                          <th>Action</th>
                          <td>{popupData.action || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Staff Name</th>
                          <td>{item.staff_name || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Staff Role</th>
                          <td>{item.staff_role || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Phone Number</th>
                          <td>{item.staff_num || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Staff profile</th>
                          <td><button className='hist_view-image-btn' onClick={() => openImagePopup(item.staff_profile)}>View Image</button></td>
                        </tr>
                        <tr>
                          <th>User ID</th>
                          <td>{item.staff_userId || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Time</th>
                          <td>{formatDate(item.time) || 'N/A'}</td>
                        </tr>
                      </>
                    )}
                    
                    {(popupData.category === 'Guest' || popupData.category === 'Dealer') && (
                      <>
                        <tr>
                          <th>Action</th>
                          <td>{popupData.action || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Name</th>
                          <td>{item.usr_name || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Position</th>
                          <td>{item.whoislogin || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Mobile Number</th>
                          <td>{item.usr_num || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Address</th>
                          <td>{item.address || 'N/A'}</td>
                        </tr>
                        {(popupData.category === 'Dealer') && (  
                          <>
                            <tr>
                              <th>Experience</th>
                              <td>{item.experience || 'N/A'} years</td>
                            </tr>
                            <tr>
                              <th>License Number</th>
                              <td>{item.licence_num || 'N/A'}</td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <th>Pincode</th>
                          <td>{item.pincode || 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Profile Image</th>
                          <td>
                            <button className='hist_view-image-btn' onClick={() => openImagePopup(item.usr_profile)}>View Image</button>
                          </td>
                        </tr>
                        {(popupData.category === 'Dealer') && (  
                          <>  
                            <tr>
                              <th>ID Proof</th>
                              <td>
                                <button className='hist_view-image-btn' onClick={() => openImagePopup(item.id_proof)}>View Image</button>
                              </td>
                            </tr>
                            <tr>
                              <th>License Image</th>
                              <td>
                                <button className='hist_view-image-btn' onClick={() => openImagePopup(item.license_path)}>View Image</button>
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <th>Verification Status</th>
                          <td>
                            {item.Ad_Verify === 2 ? 'Rejected' : item.Ad_Verify === 1 ? 'Verified' : 'Pending'}
                          </td>
                        </tr>
                      </>
                    )}

                    {(popupData.category === 'Gallery') && (
                      <>
                        <tr>
                          <th>Verify</th>
                          <td>{popupData.action}</td>
                        </tr>
                        <tr>
                          <th>Image</th>
                          <td> <button className='hist_view-image-btn' onClick={() => openImagePopup(item.car_photo)}>View Image</button></td>
                        </tr>
                        <tr>
                          <th>Time</th>
                          <td>{formatDate(item.date)}</td>
                        </tr>
                      </>
                    )}

                    {(popupData.category === 'TypeAndCategory') && (
                      <>
                        <tr>
                          <th>Action</th>
                          <td>{popupData.action}</td>
                        </tr>
                        {(item.types != null) && (
                          <tr>
                            <th>Vehicle Type</th>
                            <td>{item.types}</td>
                          </tr>
                        )}
                        {(item.category != null) && (
                          <tr>
                            <th>Vehicle Category</th>
                            <td>{item.category}</td>
                          </tr>
                        )} 
                      </>
                    )}

                    {(popupData.category === 'Vehicle') && (
                      <>
                        <tr>
                          <th>Action</th>
                          <td>{popupData.action}</td>
                        </tr>
                        <tr>
                          <th>Vehicle Type</th>
                          <td>{item.vehicle_type}</td>
                        </tr>
                        <tr>
                          <th>Vehicle Category</th>
                          <td>{item.car_type}</td>
                        </tr>
                        <tr>
                          <th>Vehicle Name</th>
                          <td>{item.car_name}</td>
                        </tr>
                        <tr>
                          <th>Vehicle Number</th>
                          <td>{item.car_number}</td>
                        </tr>
                        <tr>
                          <th>Vehicle Image</th>
                          <td><button className='hist_view-image-btn' onClick={() => openImagePopup(item.car_photo)}>View Image</button></td>
                        </tr>
                        <tr>
                          <th>Seat</th>
                          <td>{item.seat}</td>
                        </tr>
                        <tr>
                          <th>Power</th>
                          <td>{item.power}</td>
                        </tr>
                        <tr>
                          <th>Speed</th>
                          <td>{item.speed}</td>
                        </tr>
                        <tr>
                          <th>Gear</th>
                          <td>{item.gear}</td>
                        </tr>
                        <tr>
                          <th>Bag</th>
                          <td>{item.bag}</td>
                        </tr>
                        <tr>
                          <th>One day rent</th>
                          <td>{item.one_day_rent}</td>
                        </tr>
                        <tr>
                          <th>Max Km per day</th>
                          <td>{item.maxkm_day}</td>
                        </tr>
                        <tr>
                          <th>Price per Km</th>
                          <td>{item.price_per_km}</td>
                        </tr>
                        <tr>
                          <th>Per Km Minimum cost</th>
                          <td>{item.minkm_cost}</td>
                        </tr>
                        <tr>
                          <th>Rcbook Image</th>
                          <td><button className='hist_view-image-btn' onClick={() => openImagePopup(item.rcbook_path)}>View Image</button></td>
                        </tr>
                        <tr>
                          <th>Insurance Image</th>
                          <td><button className='hist_view-image-btn' onClick={() => openImagePopup(item.insurance_img)}>View Image</button></td>
                        </tr>
                        <tr>
                          <th>Apply Date</th>
                          <td>{formatDate(item.apply_date)}</td>
                        </tr>
                      </>
                    )}


                {(popupData.category === 'Contact') && (
                      <>
                        <tr>
                          <th>Action</th>
                          <td>{popupData.action}</td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>{item.email}</td>
                        </tr>
                        <tr>
                          <th>Phone Number</th>
                          <td>{item.phn}</td>
                        </tr>      
                      </>
                    )}


                  {popupData.category === 'VehicleUpdate' && (
                    <>
                      <tr>
                        <th>Vehicle Type</th>
                        <td>{item.up_vehicle_type || 'N/A'}</td>
                      </tr>
                      <tr>
                        <th>Vehicle Category</th>
                        <td>{item.up_car_type || 'N/A'}</td>
                      </tr>
                      {/* Other VehicleUpdate fields... */}
                    </>
                  )}
                  {/* Other categories... */}
                </>
              ))}
           </tbody>
      </table>
    </div>
  );
})()}

      {/* Comparison Table for VehicleUpdate */}
      {popupData?.category === 'VehicleUpdate' && popupData?.prev_data && popupData?.new_data && (
        <div>
          <p><strong>UPDATE COMPARISON:</strong></p>
          <table className="hiscustom-data-table">
            <thead>
              <tr>
                <th>Field</th>
                <th>Previous Value</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                const prevItem = JSON.parse(popupData.prev_data)[0] || {};
                const newItem = JSON.parse(popupData.new_data)[0] || {};
                
                return (
                  <>
                  <tr>
                    <th>Action</th>
                    <td colSpan="2">{popupData.action}</td>
                  </tr>
                  <tr>
                    <th>Vehicle Type</th>
                    <td>{prevItem.vehicle_type || 'N/A'}</td>
                    <td>{newItem.up_vehicle_type || ''}</td>
                  </tr>
                  <tr>
                    <th>Vehicle Category</th>
                    <td>{prevItem.car_type || 'N/A'}</td>
                    <td>{newItem.up_car_type || ''}</td>
                  </tr>
                  <tr>
                    <th>Vehicle Name</th>
                    <td>{prevItem.car_name || 'N/A'}</td>
                    <td>{newItem.up_veh_nm || '-'}</td>
                  </tr>
                  <tr>
                    <th>Vehicle Number</th>
                    <td>{prevItem.car_number || 'N/A'}</td>
                    <td>{newItem.up_car_number || ''}</td>
                  </tr>
                  <tr>
                    <th>Vehicle Image</th>
                    <td>
                      {prevItem.car_photo ? 
                        <button className='hist_view-image-btn' onClick={() => openImagePopup(prevItem.car_photo)}>
                          View Image
                        </button> : 'N/A'}
                    </td>
                    <td>
                      {newItem.up_veh_img !== 'null' ? 
                        <button className='hist_view-image-btn' onClick={() => openImagePopup(newItem.up_veh_img)}>
                          View Updated Image
                        </button> : '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>Seat</th>
                    <td>{prevItem.seat || 'N/A'}</td>
                    <td>{newItem.up_seat || ''}</td>
                  </tr>
                  <tr>
                    <th>Power</th>
                    <td>{prevItem.power || 'N/A'}</td>
                    <td>{newItem.up_power || ''}</td>
                  </tr>
                  <tr>
                    <th>Speed</th>
                    <td>{prevItem.speed || 'N/A'}</td>
                    <td>{newItem.up_speed || ''}</td>
                  </tr>
                  <tr>
                    <th>Gear</th>
                    <td>{prevItem.gear || 'N/A'}</td>
                    <td>{newItem.up_gear || ''}</td>
                  </tr>
                  <tr>
                    <th>Bag</th>
                    <td>{prevItem.bag || 'N/A'}</td>
                    <td>{newItem.up_bag || ''}</td>
                  </tr>
                  <tr>
                    <th>One day rent</th>
                    <td>{prevItem.one_day_rent || 'N/A'}</td>
                    <td>{newItem.cost_day !== '0' ? newItem.cost_day : '-'}</td>
                  </tr>
                  <tr>
                    <th>Max Km per day</th>
                    <td>{prevItem.maxkm_day || 'N/A'}</td>
                    <td>{newItem.max_kmday !== '0' ? newItem.max_kmday : '-'}</td>
                  </tr>
                  <tr>
                    <th>Price per Km</th>
                    <td>{prevItem.price_per_km || 'N/A'}</td>
                    <td>{newItem.cost_km !== '0' ? newItem.cost_km : '-'}</td>
                  </tr>
                  <tr>
                    <th>Per Km Minimum cost</th>
                    <td>{prevItem.minkm_cost || 'N/A'}</td>
                    <td>{newItem.min_km !== '0' ? newItem.min_km : '-'}</td>
                  </tr>
                  <tr>
                    <th>Rcbook Image</th>
                    <td>
                      {prevItem.rcbook_path ? 
                        <button className='hist_view-image-btn' onClick={() => openImagePopup(prevItem.rcbook_path)}>
                          View Image
                        </button> : 'N/A'}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Insurance Image</th>
                    <td>
                      {prevItem.insurance_img ? 
                        <button className='hist_view-image-btn' onClick={() => openImagePopup(prevItem.insurance_img)}>
                          View Image
                        </button> : 'N/A'}
                    </td>
                    <td>
                      {newItem.insur_img !== 'null' ? 
                        <button className='hist_view-image-btn' onClick={() => openImagePopup(newItem.insur_img)}>
                          View Updated Image
                        </button> : '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>Apply Date</th>
                    <td colSpan="2">{formatDate(prevItem.apply_date || newItem.apply_date)}</td>
                  </tr>
                </>

                );
              })()}
            </tbody>
          </table>
        </div>
      )}
    </div>
  </div>
)}
      

      {/* Image Popup */}
      {imagePopupOpen && (
        <ImagePopup
          src={popupImageSrc?`${BASE_URL}/${popupImageSrc}`:null}
          alt="Image Preview"
          onClose={closeImagePopup}
        />
      )}


      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleDelete}
        message={`Are you sure you want to delete this History details?`}
      />


    </div>
  );
};

export default HistoryDetails;
