import React from "react";
import { BASE_URL } from "../../api/config";

const RecommendCarCard = (props) => {

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const dateStr = date.toLocaleDateString([], { year: 'numeric', month: 'long', day: '2-digit' });
    return `${dateStr}`;
  };

  const {
    BookingID,
    car_photo,
    kms,
    start_date,
    end_date,
    start_place,
    end_place,
    no_days,
    amt
  } = props.item;

  return (
    <div className="recommend__car-card" onClick={props.onClick}>
      <div className="recommend__car-top">
        <h5>
          <span>
            <i class="ri-refresh-line"></i>
          </span>
          {formatDateTime(start_date)} ({start_place})<br />{formatDateTime(end_date)} ({end_place})
        </h5>
      </div>

      <div className="recommend__car-img">
        <img src={`${BASE_URL}/${car_photo}`} alt="" />
      </div>
      <div className="recommend__car-bottom">
        <div className="recommend__car-bottomflex">
          <h4>ID : {BookingID}<span> </span></h4>
          <span className="recommendfontsize">({no_days})</span>
        </div>
        <div className="recommend__car-other">
          <div className="recommend__icons">
            <p className="recommendfontsize">
              <i class="ri-repeat-line"></i>
              {kms}kms
            </p>
            <p>
              <i class="ri-settings-2-line"></i>

            </p>
            <p>
              <i class="ri-timer-flash-line"></i>
            </p>
          </div>
          <span className="recommendfontsize">₹{amt}/-</span>
        </div>
      </div>
    </div>
  );
};

export default RecommendCarCard;
