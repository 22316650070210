import React, { useState, useEffect } from "react";
import "../styles/addvehtype.css";
import { BASE_URL } from '../api/config';
import Swal from 'sweetalert2'; // Ensure SweetAlert2 is imported
import ConfirmationModal from "../components/Model/ConfirmationModal"; // Import your modal component
import { apiUrl } from "../api/api_url";

const AddVehTypeCate = () => {
  const [activeTab, setActiveTab] = useState("VehType");
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newVehicleType, setNewVehicleType] = useState("");
  const [newCategory, setNewCategory] = useState({ vehicleType: "", categoryName: "" });
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedtId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(true);

  const staffAccess = JSON.parse(localStorage.getItem("staffaccess") || "{}");
  const whoislog = localStorage.getItem("whoislog");

  useEffect(() => {
    fetchVehicleTypes();
  }, []);



  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "VehCategory") {
      const defaultTypeId = vehicleTypes[0]?.id || "";
      setNewCategory({ ...newCategory, vehicleType: defaultTypeId });
      if (defaultTypeId) fetchCategories();
    }
  };

  const fetchVehicleTypes = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await fetch(`${BASE_URL}${apiUrl.GET_VEHICLE_TYPE}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch vehicle types');
      }

      const data = await response.json();
      setVehicleTypes(data);
    } catch (error) {
      console.error('Error fetching vehicle types:', error);
    }finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await fetch(`${BASE_URL}${apiUrl.GET_VEHICLE_CATEGORY}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }

      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }finally {
      setLoading(false);
    }
  };



  const handleAddVehicleType = async (e) => {
    e.preventDefault();
    const accessToken = localStorage.getItem("accessToken");

 // Regular expression to allow letters, numbers, spaces, and common special characters
 const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]*$/;

// Validate the input value
if (!regex.test(newVehicleType)) {
  Swal.fire({
    title: "Invalid input! The vehicle type must contain at least one letter and can include numbers or spaces. Fully numeric inputs are not allowed.",    icon: 'error',
    timer: 3000,
    showConfirmButton: false,
    customClass: {
      popup: 'custom-popup',
      title: 'custom-title',
      content: 'custom-content'
    }
  });
  return; // Exit the function if the validation fails
}

    try {
      const response = await fetch(`${BASE_URL}${apiUrl.ADD_VEHICLE_TYPE}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ vehicleType: newVehicleType ,
          staffrole : whoislog,
          staffId : staffAccess.staff_logid??'0',}),
      });

      if (response.status === 409) {
        const data = await response.json(); // Parse the JSON response
        Swal.fire({
          title: data.errormsg,  // Display the error message from the backend
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,  // Hides the OK button
          customClass: {
            popup: 'custom-popup',    // Class for the alert popup
            title: 'custom-title',    // Class for the title
            content: 'custom-content' // Class for the content text
          }
  });
  setNewVehicleType(''); // Clear the vehicleType state
      return;
      }

      if (!response.ok) {
        throw new Error('Failed to add vehicle type');
      }
 
      Swal.fire({
        title: 'Vehicle Type Added Successfully',
        // text: 'Invalid OTP',
        icon: 'success',
        timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
      }).then(() => {
        fetchVehicleTypes(); // Refresh vehicle type list
        setNewVehicleType(""); // Clear form
      });
     
    } catch (error) {
      console.error('Error adding vehicle type:', error);
    }
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();

    const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]*$/;
    // Validate the input value
    if (!regex.test(newCategory.categoryName)) {
      Swal.fire({
        title: "Invalid input! The vehicle type must contain at least one letter and can include numbers or spaces. Fully numeric inputs are not allowed.",       
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
        customClass: {
          popup: 'custom-popup',
          title: 'custom-title',
          content: 'custom-content'
        }
      });
      return; // Exit the function if the validation fails
    }

    const accessToken = localStorage.getItem("accessToken");
    // console.log(newCategory.vehicleType,newCategory.categoryName)
console.log("category type ==" , newCategory.categoryName)
    try {
      const response = await fetch(`${BASE_URL}${apiUrl.ADD_VEHICLE_CATEGORY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          typeId: newCategory.vehicleType, // Use selected vehicle type ID
          category: newCategory.categoryName,
          staffrole : whoislog,
          staffId : staffAccess.staff_logid??'0',
        }),
      });

      if (response.status === 409) {
        const data = await response.json(); // Parse the JSON response
        Swal.fire({
          title: data.errormsg, 
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
          customClass: {
            popup: 'custom-popup',
            title: 'custom-title',
            content: 'custom-content'
          }
        });
        setNewCategory(prevState => ({
          ...prevState, 
          categoryName: ""
        }));
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to add category');
      }
      Swal.fire({
        title: 'Category Added Successfully',
        // text: 'Invalid OTP',
        icon: 'success',
        timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
              popup: 'custom-popup',    // Class for the alert popup
              title: 'custom-title',    // Class for the title
              content: 'custom-content' // Class for the content text
            }
      }).then(() => {
        fetchCategories(); 
        setNewCategory({ vehicleType: vehicleTypes[0]?.id, categoryName: "" }); 
      });

    } catch (error) {
      console.error('Error adding category:', error);
    }
  };


  const openModal = (Id) => {
    setSelectedId(Id);
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    if (activeTab === 'VehType') {
      await handleDeleteType(selectedtId);;
    } else if (activeTab === 'VehCategory') {
      await handleDeleteCategory(selectedtId);
    } 

    setModalOpen(false);
  };


  const handleDeleteType = async (typeId) => {
    const accessToken = localStorage.getItem("accessToken");
  
    try {
      const response = await fetch(`${BASE_URL}${apiUrl.DELETE_VEHICLE_TYPE}/${typeId}/${whoislog}/${staffAccess.staff_logid}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 400) {
        const result = await response.json();
        Swal.fire({
          title: result.message, 
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,  // Hides the OK button
            customClass: {
                popup: 'custom-popup',    // Class for the alert popup
                title: 'custom-title',    // Class for the title
                content: 'custom-content' // Class for the content text
            }
        });
       }
  
      if (!response.ok) {
        throw new Error('Failed to delete type');
      }
  
      fetchVehicleTypes(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting type:', error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    const accessToken = localStorage.getItem("accessToken");
  
    try {
      const response = await fetch(`${BASE_URL}${apiUrl.DELETE_VEHICLE_CATEGORY}/${categoryId}/${whoislog}/${staffAccess.staff_logid}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete category');
      }
  
      // Update local state to remove the deleted category
      setCategories(prevCategories => prevCategories.filter(cate => cate.id !== categoryId));
  
      fetchCategories(); // Refresh the categories after deletion
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };
  if (loading) return <center><div className="waitingspinner"></div></center>;


  return (
    <div className="typesettings">
      <div className="typesettings__wrapper">
        <h2 className="typesettings__title">Add Vehicle Type / Category</h2>

        <div className="typesettings__top">
          <button
            className={`typesetting__btn ${activeTab === "VehType" ? "active__btn" : ""}`}
            onClick={() => handleTabClick("VehType")}
          >
            Add Vehicle Type
          </button>
          <button
            className={`typesetting__btn ${activeTab === "VehCategory" ? "active__btn" : ""}`}
            onClick={() => handleTabClick("VehCategory")}
          >
            Add Vehicle Category
          </button>
        </div>

        {activeTab === "VehType" && (
          <div className="typevehType__form">
            <h3>Add Vehicle Type</h3>
            <form onSubmit={handleAddVehicleType}>
              <div className="typeform__group">
                <input
                  type="text"
                  name="vehicleType"
                  value={newVehicleType}
                  onChange={(e) => setNewVehicleType(e.target.value)}
                  placeholder="Enter Vehicle Type"
                  required
                />
              </div>
              <button type="submit" className="typeadd__btn">
                Add Vehicle Type
              </button>
            </form>

            <div className="vehType-list">
              <h4>Vehicle Types</h4>
              <div className="vehType-cards">
             {vehicleTypes.length > 0 ? (
    vehicleTypes.map((type) => (
      <div key={type.id} className="vehType-card">
        <i
          className="ri-close-fill typedelete-icon"
          onClick={() => openModal(type.id)}
        ></i>
        <p>{type.types}</p>
      </div>
    ))
  ) : (
    <p className="error-message">No vehicle types available.</p>
  )}
              </div>
            </div>
          </div>
        )}

        {activeTab === "VehCategory" && (
          <div className="vehCategory__form">
            <h3>Add Vehicle Category</h3>
            <form onSubmit={handleAddCategory}>
              <div className="cateform__group">
                <p>Select Vehicle Type</p>
                <select
                  name="vehicleType"
                  value={newCategory.vehicleType} // Set the selected vehicle type
                  onChange={(e) => {
                    setNewCategory({ ...newCategory, vehicleType: e.target.value , });
                    fetchCategories(e.target.value);
                  }}
                  required
                >
                  {vehicleTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.types}
                    </option>
                  ))}
                </select>
              </div>
              <div className="typeform__group">
                <input
                  type="text"
                  name="categoryName"
                  value={newCategory.categoryName}
                  onChange={(e) => {
                    console.log("Category Name: ", e.target.value); // Log the category name value
                    setNewCategory({ ...newCategory, categoryName: e.target.value });
                  }}
                  
                  placeholder="Enter Category Name"
                  required
                />
              </div>
              <button type="submit" className="add__category-btn">
                Add Category
              </button>
            </form>
          <div className="category-list">
  <h4>Categories for Selected Vehicle Type</h4>
  {vehicleTypes.length > 0 ? (
    <ul>
      {vehicleTypes.map((type) => (
        <li key={type.id}>
          {type.types}'s Categories
          {categories.some(cate => cate.type_id === type.id) ? (
            <ul style={{ display: 'flex', listStyleType: 'none', padding: 0, flexWrap: 'wrap' }}>
              {categories
                .filter(cate => cate.type_id === type.id)
                .map((cate) => (
                  <li key={cate.id} className="subcate-li" style={{ margin: '5px' }}>
                    <span>{cate.category}</span>
                    <i
                      className="ri-close-fill"
                      onClick={() => openModal(cate.id)}
                    ></i>
                  </li>
                ))}
            </ul>
          ) : (
            <p className="error-message">No categories available for this vehicle type.</p>
          )}
        </li>
      ))}
    </ul>
  ) : (
    <p className="error-message">No vehicle types available.</p>
  )}
</div>



          </div>
        )}
      </div>
      <ConfirmationModal 
        isOpen={isModalOpen} 
        onClose={() => setModalOpen(false)} 
        onConfirm={handleConfirm} 
        message={`Are you sure you want to delete ?`} 
      />
    </div>
  );
};

export default AddVehTypeCate;
