

export const apiUrl = {

//Staff Auth   
    STAFF_LOGIN_AUTH: '/api/Auth/Staff_login',

//HistoryDetails
    GET_LOG_HISTORY :'/api/Dashboard/getWorking_History_Details',
    DELETE_HISTORY : '/api/Dashboard/deleteHistory',

//STAFFPAGES
    GET_ALLSTAFF_DETAILS : '/api/Dashboard/getAllSeataxiStaff',    
    DELETE_STAFF : '/api/Dashboard/deleteSetaxiStaff',    
    UPDATE_STAFF_ACCESS : '/api/Dashboard/update-staff-access',    

//VIEWCLIENT DETAILS
    CLIENT_BOOKING_DETAILS : '/api/Client/Booking/BookingDtls',
    USER_APPROVAL_REQ : '/api/Dashboard/ADMIN_Approve_Dealer',
    UPDATE_REJECT_REASON : '/api/Dashboard/updateRejectReason',

//VIEWDEALER DETAILS
    GET_DEALERS_TAXI : '/api/getDealerTaxiEntries',
    DEALER_BOOKING_ORDERS : '/api/DealerOrderDtls',

//VIEW VEHICLE DETAILS
    GET_GUEST_GALLERYIMAGE : '/api/Client/getGuestGalleryImg',
    VEHICLE_APPROVAL_REQ : '/api/Dashboard/ADMIN_Approve_Vehicle',


//ADD VEHICLE TYPE AND CATEGORY
    GET_VEHICLE_TYPE : '/api/Vehicle/fetchvehType',
    GET_VEHICLE_CATEGORY : '/api/Vehicle/fetchVehicleCategories',
    ADD_VEHICLE_TYPE : '/api/Dashboard/addVehicleType',
    ADD_VEHICLE_CATEGORY : '/api/Dashboard/addVehicleCategory',
    DELETE_VEHICLE_TYPE : '/api/Dashboard/DeleteVehType',
    DELETE_VEHICLE_CATEGORY : '/api/Dashboard/DeleteVehCateg',


//ALL ORDER DETAILS
    ALL_BOOKING_DETAILS : '/api/Dashboard/getAllOrders',

//ALL VEHICLE DETAILS
    GET_ACTIVE_TAXI : '/api/Vehicle/getTaxiEntries',
    GET_DEACTIVE_TAXI : '/api/Vehicle/getDeactiveTaxiEntries',

//CLIENT DETAILS
    GET_CLIENT_DETAILS : '/api/Dashboard/getClientDtls',

//DASHBOARD
    GET_ALL_USER_DETAILS : '/api/Dashboard/getAllUsersDtls',
    VEHICLE_REQ_COUNT : '/api/Dashboard/getVehReqCount',
    VEHICLE_UPDATE_REQ_COUNT : '/api/Dashboard/getVehicleUpdatesCount',
    UPCOMING_ORDER_DETAILS : '/api/Dashboard/getUpComingDetails',
    UNRESPOND_ORDER_DETAILS : '/api/Dashboard/getUnRespondOrderDetails',

//DEALER DETAILS
    GET_ALL_DEALERS : '/api/Dashboard/getAllDealers',
    GET_UNVERIFY_DEALERS : '/api/Dashboard/getUnverifiedDealers',
    DELETE_DEALERS : '/api/Dashboard/RemoveDealer',


//DEALER REQUEST
    GET_DEALER_REQUEST : '/api/Dashboard/getRegDealers',

//PROFILE AND CONTACT
    GET_CONTACTUS : '/api/Contact/contactus',
    ADD_CONTACTUS : '/api/Contact/contactus',
    DELETE_CONTACTUS : '/api/Contact/contactus',
    UPDATE_STAFF_PROFILE : '/api/Dashboard/Update_Staff_Profile',

//SEATAXI BOOKINGS
    GET_ADMIN_BOOKINGS : '/api/Dashboard/AllBookingDetailsADWEB',

//UPDATE REQUEST
    GET_VEHICLE_UPDATE_REQUEST : '/api/Dashboard/getVehicleUpdates',
    GET_UPDATE_VEHICLE_DETAILS : '/api/Dashboard/getUpdateCarDetails',
    GET_PENDING_GALLERY : '/api/Dashboard/getGalleryPendingImg',
    APPROVE_VEHICLE_UPDATE : '/api/Dashboard/ADMIN_Approve_VehicleUpdate',
    APPROVE_GALLERY_UPDATE : '/api/Dashboard/ADMIN_GalleryUpdate',
    DELETE_GALLERY_IMAGE : '/api/Dealer/deleteGalImg',

//VEHICLE REQUEST 
    GET_PENDING_REQ_VEHICLE : '/api/Dashboard/getRegDealersVehicle',
    DELETE_TAXI_VEHICLE : '/api/Dashboard/deleteTaxi-Web',


//WEB CONTACT
    GET_WEBSITE_CONTACT : '/api/getWebContact/getWebsiteContastus',

//CHANGE PASSWORD
    CHANGE_PASSWORD : '/api/Auth/change-password',

//DEALER FORM
    ADD_DEALER : '/api/Dashboard/RegisterDealerWeb',

//STAFF FORM
    ADD_STAFF : '/api/Dashboard/add-staff',

//TOP NAVBAR
    GET_NOTIFICATIONS : '/api/user/getNotific',
    MARK_AS_READ : '/api/user/MarkasRead',




}