import React, {useEffect} from 'react';
import Header from './Header';
import "../assets/css/about.css";
import about_pic from "../assets/image/taxng.png";
import Footer from './Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out',
    });
    // Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  return (
   <>
   <Header />
   <section id="ABOUT">
   <div className='sea_taxi_about_main_div'>
       <div className='blurred_background'></div> {/* Added this div for the blur effect */}
       <div className='sea_taxi_about_main_div_1'>
            <div className='about_taxi_pic_add' data-aos="fade-up">
                <img src={about_pic} alt="" />
            </div>
        <div className='about_taxi_about_conts' data-aos="fade-up">
            <h2>GET TO KNOW US</h2>
            <div className='about_line_div'></div>
            <p>At Sea taxi, We pride ourselves on offering safe, reliable and efficient taxi service that cater to all your transportation needs. With years of experience in the industry, our professional drivers are dedicated to providing punctual and friendly service, ensuring that you reach your destination comfortably and on time.</p>

            <h4>Our Vision</h4>
            <p>To be the leading taxi service provider in our region, recognized for our commitment to excellence, innovation, and sustainability. We aim to enhance mobility solutions while minimizing our environmental impact.Sea Taxi made my trip to the airport stress-free! The driver was friendly and on time. I highly recommend their service!.We operate a diverse fleet of vehicles to accommodate all group sizes and preferences, from sedans to larger vans. All our vehicles are regularly maintained for safety and comfort.At Sea Taxi, we are committed to reducing our environmental footprint. We implement eco-friendly practices and are working towards integrating hybrid and electric vehicles into our fleet.</p>

            <h4>Ready to Ride?</h4>
            <p>Experience the Sea Taxi difference today! Book your ride now and enjoy a smooth and safe journey.</p>
        </div>
    </div>
   </div>
   </section>
   <Footer />
   </>
  )
}

export default React.memo(About);
