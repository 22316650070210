/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../api/config'; 
import "./viewstyle/viewdealerdtl.css"
import ConfirmationModal from "../../components/Model/ConfirmationModal";
import ImagePopup from '../../components/reuseable/ImagePopup';
import Swal from 'sweetalert2';
import { apiUrl } from '../../api/api_url';

const ViewClientdtl = () => {
  const location = useLocation();
  const { client } = location.state || {};
  const [bookingDetails, setBookingDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isModalOpen, setModalOpen] = useState(false); // Modal state
  const [actionType, setActionType] = useState(''); // Action type for confirmation
  const [dealerToApprove, setDealerToApprove] = useState(null);

  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');

  const [ClientActivateAccess, setClientActivateAccess] = useState(false);

  const staffAccess = JSON.parse(localStorage.getItem("staffaccess") || "{}");
  const whoislog = localStorage.getItem("whoislog");


  
  useEffect(() => {
    const staffAccess = JSON.parse(localStorage.getItem("staffaccess") || "{}");
    const whoislog = localStorage.getItem("whoislog");
    const accessCategory = staffAccess.access_category
    ? JSON.parse(staffAccess.access_category)
    : {};
   
    if ((staffAccess && accessCategory?.["Client"]?.ActiveDeactivate) || whoislog === 'Admin') {
      setClientActivateAccess(true);
    } else {
      setClientActivateAccess(false);
    }
    if (client) {
      fetchClientBookingDetails();
    }
  }, [client]);



  const fetchClientBookingDetails = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${BASE_URL}${apiUrl.CLIENT_BOOKING_DETAILS}`,
        { logId: client.id, logNum: client.usr_num },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setBookingDetails(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching booking details:', error);
      setIsLoading(false);
    }
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const dateStr = date.toLocaleDateString([], { year: 'numeric', month: 'long', day: '2-digit' });
    return `${dateStr}`;
  };


  const formatDateTimeNew = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long', // 'short' for abbreviated month name
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Change to false for 24-hour format
    };
  
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options); // Adjust 'en-US' as needed
  };


  const openModal = (id, numbr, verify) => {
    setActionType(verify === 1 ? 'Activate' : verify === 2 ? 'Deactivate' : 'Approve');
    setDealerToApprove({ id, numbr, verify });
    setModalOpen(true);
  };

  const handleAdminApprove = async () => {
    if (!dealerToApprove) return;

    const { id, numbr, verify } = dealerToApprove;
    const accessToken = localStorage.getItem('accessToken');
console.log('dealerToApprove',dealerToApprove);
    try {
      const response = await axios.post(
        `${BASE_URL}${apiUrl.USER_APPROVAL_REQ}`,
        {
          id: id.toString(),
          numbr: numbr.toString(),
          verify: verify,
          Del_id: id.toString(),

          staffrole : whoislog,
          staffId : staffAccess.staff_logid??'0',
          action: verify === 1 ? "Activate" :verify === 2 ? "Deactive" : ""
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        const message = response.data;
        console.log("Message:", message);

        if (verify === 1) {
  
          await Swal.fire({
            title: 'Client Activated',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            customClass: {
              popup: 'custom-popup',
              title: 'custom-title',
              content: 'custom-content'
            }
          });
          window.history.back();

        } else if (verify === 2) {
          await axios.post(
            `${BASE_URL}${apiUrl.UPDATE_REJECT_REASON}`,
            {
              action: "DealerReject",
              id: id.toString(),
              tokn: numbr.toString(),
              dealid: id.toString(),
              rej_rsn: "Your Rejection Reason",
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          await Swal.fire({
            title: 'Client Deactivated',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            customClass: {
              popup: 'custom-popup',
              title: 'custom-title',
              content: 'custom-content'
            }
          });
          window.history.back();
        }
        
      } else {
        alert("Action failed");
      }
    } catch (error) {
      console.error("Error in ADMIN approve function:", error);
    }
    setModalOpen(false); // Close the modal after the action is taken
  };

  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setImagePopupOpen(true);
  };

  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };


  if (isLoading) return <center><div className="waitingspinner"></div></center>;

  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="dealer-details-container">
          { client ? (
            <>
              <div className='activetitleTop'>
              <h2 className='headmargin'>{client.usr_name}'s Details</h2>
            {ClientActivateAccess&&(  
              <button
  className="activeDeactive_btn"
  style={{ backgroundColor: getbtnColor(client.Ad_Verify) }}
  onClick={() => openModal(client.id, client.usr_num, 
    client.Ad_Verify === 1 
    ? 2 
    : client.Ad_Verify === 2 
    ? 1 
    : client.Ad_Verify === 0 
    ? 2 
    : 0)}
>
  {client.Ad_Verify === 1
    ? "Deactivate"
    : client.Ad_Verify === 2
    ? "Activate"
    : client.Ad_Verify === 0
    ? "Deactivate"
    : "Unknown"}
</button>
)}
              </div>

              {/* Dealer Information Table */}
              <table className="dealer-info-table">
                <thead>
                  <tr>
                    <th>Profile Photo</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Phone Number</th>
                    <th>Pincode</th>         
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                   

<img
  src={client.usr_profile ? `${BASE_URL}/${client.usr_profile}` : require('../../assets/images/profile-02.png')}
  alt={`${client.usr_name} Profile`}
  className="dlrprofile-photo"
  onClick={() => client.usr_profile && openImagePopup(`${BASE_URL}/${client.usr_profile}`)}
/>
                    </td>
                    <td>{client.usr_name}</td>
                    <td>{client.address}</td>
                    <td>{client.usr_num}</td>
                    <td>{client.pincode}</td>
                    <td style={{ color: getColor(client.Ad_Verify) }}>{
                    client.Ad_Verify === 0 ? 'Active' : client.Ad_Verify === 1 ? 'Active' :client.Ad_Verify === 2 ? 'Deactive' : 'Unknown'}</td>
                  </tr>
                </tbody>
              </table>

              {/* Booking Details Table */}
              <h3 className='headmargin'>Booking Details</h3>
              {bookingDetails.length > 0 ? (
                <table className="booking-details-table">
                  <thead>
                    <tr>
                      <th>Booking ID</th>

                      <th>Veh Photo</th>
                      <th>Trip Place from / to</th>
                      <th>Trip Date from / to</th>
                      <th>Dealer Number</th>
                      <th>Booking date</th>
                      <th>Booking Status</th>
                      {/* Add more columns as needed */}
                    </tr>
                  </thead>
                  <tbody>
                    {bookingDetails.map((booking, index) => (
                      <tr key={index}>
                        <td>{booking.BookingID}</td>
                        <td>
                            <img
                            src={`${BASE_URL}/${booking.car_photo}`}
                            alt={`${booking.car_name} pic`}
                            className="vh-photo"
                            onClick={() => openImagePopup(`${BASE_URL}/${booking.car_photo}`)}
                            />
                        </td>
                        <td>{booking.start_place}<br />{booking.end_place}</td>
                        <td>{formatDateTime(booking.start_date)}<br />{formatDateTime(booking.end_date)}</td>
                        <td>{booking.Del_num}</td>
                        <td>{formatDateTimeNew(booking.dateTime)}</td>
                        <td style={{ color: getBookColor(booking.verify) }}>
                        {booking.verify === "0" ? 'Pending' : 
                            booking.verify === "200" ? 'Accepted' : 
                            booking.verify === "300" ? 'Finished' : 
                            booking.verify === "404" ? 'Canceled' : 'Unknown'}
                    </td>
                        {/* Add more booking details as needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className='headmargin emptymsgstyle'>No booking details available.</p>
              )}
              <ConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleAdminApprove}
                message={`Are you sure you want to ${actionType} this client ?`}
              />

{imagePopupOpen && (
  <ImagePopup 
    src={popupImageSrc} 
    alt="Vehicle" 
    onClose={closeImagePopup} 
  />
)}

            </>
          ) : (
            <p className='headmargin emptymsgstyle'>No dealer data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

const getColor = (verifyStatus) => {
  switch (verifyStatus) {
    case 0:
      return "green"; // Pending
    case 1:
      return "green"; // Accepted
    case 2:
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};
const getBookColor = (verifyStatus) => {
    switch (verifyStatus) {
      case "0":
        return "orange"; // Pending
      case "200":
        return "green"; // Accepted
      case "300":
        return "blue"; // Finished
      case "404":
        return "red"; // Canceled
      default:
        return "gray"; // Unknown
    }
  };

  const getbtnColor = (verifyStatus) => {
    switch (verifyStatus) {
      case 0:
        return "darkred"; // Pending
      case 2:
        return "darkgreen"; // Accepted
      case 1:
        return "darkred"; // Finished
      default:
        return "gray"; // Unknown
    }
  };

export default ViewClientdtl;
