import React, { useState } from "react";
import "./AddStaff.css";
import { BASE_URL } from "../../../api/config";
import Swal from "sweetalert2";
import { apiUrl } from "../../../api/api_url";

const AddStaff = ({ onClose, onSubmitSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [selectedAccess, setSelectedAccess] = useState({});
  
  const categories = [
    {
      name: "Client",
      actions: ["View","ActiveDeactivate"],
    },
    {
      name: "Dealer",
      actions: ["Add","View","ActiveDeactivate"],
    },
    {
      name: "Vehicle",
      actions: ["View","ActiveDeactivate"],
    },
    {
      name: "Pending Request",
      actions: ["Dealer", "Vehicle", "Update"],
    },
    {
      name: "Add Access",
      actions: ["Vehicle_TypeCategory", "Contact"],
    },
    {
      name: "Bookings",
      actions: ["View"],
    },
    {
      name: "Customer Contactus",
      actions: ["View"],
    },
  ];

  const actionDisplayNames = {
    ActiveDeactivate: "Activate And Deactivate",
    Add: "Add",
    Dealer: "Dealer",
    Vehicle: "Vehicle",
    Update: "Update",
    Vehicle_TypeCategory: "Vehicle Type/Category",
    Contact: "Contact",
  };

  const handleCheckboxChange = (category, action) => {
    setSelectedAccess((prev) => {
      const newAccess = { ...prev };
  
      // If "View" is unchecked for "Client", "Dealer", or "Vehicle", uncheck "Activate And Deactivate"
      if (
        (category === "Client" || category === "Dealer" || category === "Vehicle") &&
        action === "View"
      ) {
        // Check if any other action is checked
        const isAnyActionChecked = Object.keys(newAccess[category] || {}).some(
          (key) => key !== "View" && newAccess[category][key]
        );
  
        if (isAnyActionChecked) {
          return prev; 
        }
  
        newAccess[category] = {
          ...newAccess[category],
          View: !newAccess[category]?.[action], // Toggle "View"
          ActiveDeactivate: newAccess[category]?.View ? newAccess[category]?.ActiveDeactivate : false, // Uncheck "Activate And Deactivate" if "View" is unchecked
        };
      }
      // If "Add" is checked/unchecked for "Dealer", automatically check "View"
      else if (category === "Dealer" && action === "Add") {
        newAccess[category] = {
          ...newAccess[category],
          View: true, // Ensure "View" is always checked when "Add" is checked
          [action]: !newAccess[category]?.[action],
        };
      }
      // If "ActiveDeactivate" is checked/unchecked for "Client", "Dealer", or "Vehicle", automatically check "View"
      else if (
        (category === "Client" || category === "Dealer" || category === "Vehicle") &&
        action === "ActiveDeactivate"
      ) {
        newAccess[category] = {
          ...newAccess[category],
          View: true, // Ensure "View" is always checked when "ActiveDeactivate" is checked
          [action]: !newAccess[category]?.[action],
        };
      } else {
        newAccess[category] = {
          ...newAccess[category],
          [action]: !newAccess[category]?.[action],
        };
      }
  
      return newAccess;
    });
  };

  const handleGlobalCheckAll = (isChecked) => {
    const updatedCategories = categories.reduce((acc, category) => {
      const allActions = category.actions.reduce((actionsAcc, action) => {
        actionsAcc[action] = isChecked;
        return actionsAcc;
      }, {});
      acc[category.name] = allActions;
      return acc;
    }, {});
  
    setSelectedAccess(updatedCategories);
  };
  
  // Check if all categories are fully selected
  const isGlobalCheckAll = categories.every((category) =>
    category.actions.every((action) => selectedAccess[category.name]?.[action])
  );

  const hasAtLeastOneAccess = () => {
    return Object.keys(selectedAccess).some(category => 
      Object.values(selectedAccess[category]).some(val => val)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formElements = e.target.elements;

    // Name validation
    const name = formElements.name.value.trim();
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!nameRegex.test(name)) {
      Swal.fire({
        title: "Invalid Name",
        text: "Name must contain only letters and spaces.",
        icon: "error",
        showConfirmButton: true,
      });
      setLoading(false);
      return;
    }

    // Phone number validation
    const number = formElements.number.value.trim();
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(number)) {
      Swal.fire({
        title: "Invalid Phone Number",
        text: "Phone number must be 10 digits and start with 6, 7, 8, or 9.",
        icon: "error",
        showConfirmButton: true,
      });
      setLoading(false);
      return;
    }

    // Check for repeating digits in the phone number
    const isRepeating = number.split("").every((digit) => digit === number[0]);
    if (isRepeating) {
      Swal.fire({
        title: "Invalid Phone Number",
        text: "Phone number should not have repeated digits.",
        icon: "error",
        showConfirmButton: true,
      });
      setLoading(false);
      return;
    }

    // User ID validation
    const userId = formElements.userId.value.trim();
    const userIdRegex = /^[A-Za-z]{2,3}\d{4,}$/; // 2-3 letters followed by 4+ digits
    if (!userIdRegex.test(userId)) {
      Swal.fire({
        title: "Invalid User ID",
        text: "User ID must start with 2-3 letters followed by 4 or more digits (e.g., SSA1005).",
        icon: "error",
        showConfirmButton: true,
      });
      setLoading(false);
      return;
    }

    // Password validation
    const password = formElements.password.value.trim();
    if (password.length < 8) {
      Swal.fire({
        title: "Password Too Short",
        text: "Password must be at least 8 characters long.",
        icon: "error",
        showConfirmButton: true,
      });
      setLoading(false);
      return;
    }

    // Check for at least one access permission
    if (!hasAtLeastOneAccess()) {
      Swal.fire({
        title: "Access Required",
        text: "Please select at least one access permission.",
        icon: "error",
        showConfirmButton: true,
      });
      setLoading(false);
      return;
    }
  
    const formData = new FormData();
    // Append fields to FormData
    formData.append("name", name);
    formData.append("number", number);
    formData.append("role", formElements.role.value.trim());
    if (formElements.profile.files[0]) {
      formData.append("profile", formElements.profile.files[0]); // File upload
    }
    formData.append("userId", userId);
    formData.append("password", password);
    formData.append("selectedAccess", JSON.stringify(selectedAccess));
    
    const staffAccess = JSON.parse(localStorage.getItem("staffaccess") || "{}");
    const whoislog = localStorage.getItem("whoislog");

    formData.append('staffrole',whoislog);
    formData.append('staffId',staffAccess.staff_logid);

    const accessToken = localStorage.getItem("accessToken");

    try {
      // Send data to the backend using fetch
      const response = await fetch(`${BASE_URL}${apiUrl.ADD_STAFF}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        Swal.fire({
          title: "User added successfully!",
          icon: "success",
          showConfirmButton: true,
          customClass: {
            popup: "custom-popup",
            title: "custom-title",
            content: "custom-content",
          },
        }).then(() => {
          onSubmitSuccess();
        });
      } else {
        Swal.fire({
            title: 'Error!',
            text: data.message,
            icon: 'error',
            timer: 2000,
        });
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      Swal.fire({
        title: 'Error!',
        text: 'Error submitting the form. Please try again.',
        icon: 'error',
        timer: 2000,
      });
      setLoading(false);
    }
  };

  return (
    <div className="staff-form-container">
      <h2 className="staff-form-title">Add User</h2>
      <form className="add-staff-form" onSubmit={handleSubmit}>
        <div className="add-staff-row add-staff-row-name">
          <label htmlFor="name" className="add-staff-label add-staff-label-name">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            className="add-staff-input add-staff-input-name orange-border"
            required
          />
        </div>
        <div className="add-staff-row add-staff-row-number">
          <label htmlFor="number" className="add-staff-label add-staff-label-number">
            Phone Number
          </label>
          <input
            type="text"
            id="number"
            name="number"
            placeholder="Mobile Number"
            className="add-staff-input add-staff-input-number orange-border"
            required
            maxLength="10"
          />
        </div>
        <div className="add-staff-row add-staff-row-role">
          <label htmlFor="role" className="add-staff-label add-staff-label-role">
            Role
          </label>
          <input
            type="text"
            id="role"
            name="role"
            placeholder="Role eg:(Manager, Hr, etc..)"
            className="add-staff-input add-staff-input-role orange-border"
            required
          />
        </div>
        
        <div className="add-staff-row add-staff-row-profile">
          <label htmlFor="profile" className="add-staff-label add-staff-label-profile">
            Profile <span className="mandatory">*</span>
          </label>
          <input
            type="file"
            id="profile"
            name="profile"
            className="add-staff-input add-staff-input-profile"
            accept="image/*"
            required
          />
        </div>
        <div className="add-staff-row add-staff-row-user-id">
          <label htmlFor="userId" className="add-staff-label add-staff-label-user-id">
            User ID
          </label>
          <input
            type="text"
            id="userId"
            name="userId"
            placeholder="User ID eg:(SSA1005)"
            className="add-staff-input add-staff-input-user-id orange-border"
            required
          />
        </div>
        <div className="add-staff-row add-staff-row-password">
          <label htmlFor="password" className="add-staff-label add-staff-label-password">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Password (min 8 characters)"
            className="add-staff-input add-staff-input-password orange-border"
            required
            minLength="8"
          />
        </div>

        <label htmlFor="access" className="add-staff-label add-staff-label-access">
          Access Permission :
        </label>
        <div style={{margin:'10px'}}>
          <label style={{color:'black'}}>
            <input
              type="checkbox"
              onChange={(e) => handleGlobalCheckAll(e.target.checked)}
              checked={isGlobalCheckAll}
            /> Check All
          </label>
        </div>
        <div className="add-staff-row add-staff-row-access">
          <div className="access-grid">
            {categories.map((category) => (
              <div key={category.name} className="access-category">
                <h4>{category.name}:</h4>
                <div className="access-actions">
                  {category.actions.map((action) => (
                    <label key={action} className="access-action">
                      <input
                        type="checkbox"
                        checked={selectedAccess[category.name]?.[action] || false}
                        onChange={() => handleCheckboxChange(category.name, action)}
                      />
                      {actionDisplayNames[action] || action}
                    </label>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="add-staff-actions">
          <button type="submit" className="add-staff-btn add-staff-btn-submit" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
          <button
            type="button"
            className="add-staff-btn add-staff-btn-cancel"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddStaff;