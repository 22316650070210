import React, { useEffect } from 'react';
import './PrivacyPolicy.css'; // Importing the CSS file for styles
import about_pic from "../../assets/image/double app.png";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">Privacy Policy for SeaTaxi</h1>
      <p className="privacy-policy-updated">Last Updated: December 2024</p>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subtitle">Introduction</h2>
        <p className="privacy-policy-text">
          At SeaTaxi, we are committed to protecting your privacy. This Privacy Policy explains how we collect,
          use, disclose, and safeguard your information when you use our services through the SeaTaxi app and
          website. By using our services, you agree to the collection and use of information in accordance with
          this policy.
        </p>
      </section>

      <div className="policyrow">
        <div className="policycol1">
          <section className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">Information We Collect</h2>
            <p className="privacy-policy-text">
              We collect personal information to provide you with a seamless and personalized experience. The types
              of information we collect include:
            </p>
            <ul className="privacy-policy-list">
              <li className="privacy-policy-list-item">Personal identification information (name, email address, phone number, etc.)</li>
              <li className="privacy-policy-list-item">Geolocation data for ride tracking and service optimization</li>
              <li className="privacy-policy-list-item">Device information (IP address, operating system, device model, etc.)</li>
              <li className="privacy-policy-list-item">Images or photos (such as profile picture or vehicle details)</li>
            </ul>
          </section>

          <section className="privacy-policy-section">
            <h2 className="privacy-policy-subtitle">How We Use Your Information</h2>
            <p className="privacy-policy-text">
              The information we collect is used for the following purposes:
            </p>
            <ul className="privacy-policy-list">
              <li className="privacy-policy-list-item">To provide and improve our taxi services</li>
              <li className="privacy-policy-list-item">To send updates about your rides, promotions, and other related information</li>
              <li className="privacy-policy-list-item">To enhance security and prevent fraud</li>
              <li className="privacy-policy-list-item">To analyze app usage and improve user experience</li>
            </ul>
          </section>
        </div>
        <img src={about_pic} alt="" />
      </div>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subtitle">How We Share Your Information</h2>
        <p className="privacy-policy-text">
          We do not sell or rent your personal information to third parties. However, we may share your data with
          the following entities for the purposes described in this policy:
        </p>
        <ul className="privacy-policy-list">
          <li className="privacy-policy-list-item">Third-party service providers (such as hosting services, etc.)</li>
          <li className="privacy-policy-list-item">Law enforcement agencies if required by law or to protect the rights of SeaTaxi</li>
          <li className="privacy-policy-list-item">Partners or affiliates involved in the operation of the service</li>
        </ul>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subtitle">Data Security</h2>
        <p className="privacy-policy-text">
          We take reasonable measures to protect your personal information from unauthorized access, loss, or
          misuse. While we implement security practices to safeguard your data, no method of transmission over the
          internet or electronic storage is 100% secure.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subtitle">Data Retention and Deletion</h2>
        <p className="privacy-policy-text">
          We retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy
          Policy or as required by law. You may request the deletion of your data by contacting us at the
          provided contact information.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subtitle">Your Data Rights</h2>
        <p className="privacy-policy-text">
          Depending on your location, you may have certain rights regarding your personal data, including the right
          to access, correct, or delete your information. To exercise these rights, please contact us at the
          provided contact information.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subtitle">Changes to This Privacy Policy</h2>
        <p className="privacy-policy-text">
          SeaTaxi reserves the right to update or modify this Privacy Policy at any time. Any changes will be
          reflected on this page, with the “Last Updated” date at the top of the page. We encourage you to review
          this policy periodically.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-subtitle">Contact Us</h2>
        <p className="privacy-policy-text">
          If you have any questions or concerns about this Privacy Policy or how your data is being handled, please
          contact us at:
        </p>
        <p className="marginbotm">Email: <a href="mailto:support@seataxi.com" className="privacy-policy-link">seataxi@seasense.com</a></p>
        <p className="marginbotm">Phone:  <a href="tel:+917402616151" className="privacy-policy-link"><span className="privacy-policy-phone">+91 74026 16151</span></a></p>
        <p className="marginbotm">Postal Address: SeaSense Softwares Pvt Ltd, Near New BusStand, Marthandam, Kanyakumari.</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
