import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const [navLinks, setNavLinks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch localStorage data
    const staffAccess = JSON.parse(localStorage.getItem("staffaccess") || "{}");
    const whoislog = localStorage.getItem("whoislog");

    // Parse the access_category string into an object
    const accessCategory = staffAccess.access_category
      ? JSON.parse(staffAccess.access_category)
      : {};

    console.log("staffAccess:", staffAccess);
    console.log("whoislog:", whoislog);
    console.log("Parsed access_category:", accessCategory);

    // Dynamically construct the navLinks based on whoislog and accessCategory
    const updatedNavLinks = [
      {
        path: "/layout/dashboard",
        icon: "ri-home-line",
        display: "Home",
      },
      {
        path: "/layout/profile",
        icon: "ri-user-line",
        display: 
        whoislog === "Admin" || accessCategory?.["Add Access"]?.Contact ? "Profile / Contact" :"Profile",
      },

      ...(whoislog === "Admin" ||
        accessCategory?.["Add Access"]?.Vehicle_TypeCategory
          ? [
      {
        path: "/layout/addveh_type_cate",
        icon: "ri-add-box-line",
        display: "Add Type / Category",
      },  ]
      : []),
      ...(whoislog === "Admin" ||
      accessCategory?.["Pending Request"]?.Dealer
        ? [
            {
              path: "/dealer-requests",
              icon: "ri-building-line",
              display: "Dealer Request",
            },
          ]
        : []),
      ...(whoislog === "Admin" ||
      accessCategory?.["Pending Request"]?.Vehicle
        ? [
            {
              path: "/vehicle-requests",
              icon: "ri-list-check",
              display: "Vehicle Request",
            },
          ]
        : []),

        ...(whoislog === "Admin" ||
          accessCategory?.["Pending Request"]?.Update
            ? [  
      {
        path: "/update-requests",
        icon: "ri-pencil-line",
        display: "Update Request",
      },
    ]
    : []),
    ...(whoislog === "Admin" ||
      accessCategory?.["Bookings"]?.View
        ? [      
      {
        path: "/layout/allorderdtls",
        icon: "ri-file-list-3-line",
        display: "All Booking History",
      },
    ]
    : []),
    ...(whoislog === "Admin" ||
      accessCategory?.["Vehicle"]?.View
        ? [     
      {
        path: "/layout/allVehdetails",
        icon: "ri-car-line",
        display: "All Vehicle Details",
      },
    ]
    : []),

      ...(whoislog === "Admin" ||
        accessCategory?.["Dealer"]?.View
          ? [      
      {
        path: "/layout/dealerdtls",
        icon: "ri-store-line", // Dealer Details icon
        display: "Dealer Details",
      }, ]
      : []),

      ...(whoislog === "Admin" ||
        accessCategory?.["Client"]?.View
          ? [      
      {
        path: "/layout/clientdtls",
        icon: "ri-user-3-line", // Client Details icon
        display: "Client Details",
      },
    ]
    : []),
    ...(whoislog === "Admin" ||
      accessCategory?.["Customer Contactus"]?.View
        ? [ 
      {
        path: "/layout/customercontacts",
        icon: "ri-phone-line",
        display: "Customer contacts",
      },
    ]
    : []),
      ...(whoislog === "Admin"
          ? [  
      {
        path: "/layout/add_seataxi_staff",
        icon: "ri-user-add-line",
        display: "Users and Role",
      },
    ]
    : []),
    

    ...(whoislog === "Admin"
      ? [  
      {
        path: "/layout/seataxi_history_details",
        icon: "ri-history-line",
        display: "History",
      },
    ]
    : []),


    ];

    setNavLinks(updatedNavLinks); // Update the state with the dynamic nav links
  }, []);

  // Handle logout
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear session and local storage items
        localStorage.removeItem("accessToken");
        localStorage.removeItem("Number");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("whoislog");
        localStorage.removeItem("staffaccess");

        sessionStorage.clear();
        localStorage.clear();

        // Redirect to login page
        navigate("/login");
      }
    });
  };

  return (
    <div className="sidebar">
      <div className="sidebar__content">
        <div className="menu">
          <ul className="nav__list">
            {navLinks.map((item, index) => (
              <li className="nav__item" key={index}>
                <NavLink
                  to={item.path}
                  className={(navClass) =>
                    navClass.isActive ? "nav__active nav__link" : "nav__link"
                  }
                >
                  <i className={item.icon}></i>
                  {item.display}
                </NavLink>
              </li>
            ))}
            <li>
              <div className="sidebar__bottom">
                <span onClick={handleLogout} style={{color:'red'}}>
                  <i className="ri-logout-circle-r-line"></i> Logout
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
