import React, { useState, useEffect } from "react";
import "../styles/dashboard.css";
import SingleCard from "../components/reuseable/SingleCard";
import RecommendCarCard from "../components/UI/RecommendCarCard";
import { BASE_URL } from '../api/config';
import { useNavigate } from 'react-router-dom';

import CarChart from "../charts/CarStatsChart"
import BookingStatsChart from "../charts/MileCharts"
import { apiUrl } from "../api/api_url";

const Dashboard = () => {
  const [DealerView, setDealerView] = useState(false);
  const [ClientView, setClientView] = useState(false);

  const [DealerApproveAccess, setDealerApproveAccess] = useState(false);
  const [VehicleApproveAccess, setVehicleApproveAccess] = useState(false);
  const [UpdateApproveAccess, setUpdateApproveAccess] = useState(false);


  const navigate = useNavigate();

 
 
  useEffect(() => {
    const staffAccess = JSON.parse(localStorage.getItem("staffaccess") || "{}");
    const whoislog = localStorage.getItem("whoislog");
    const accessCategory = staffAccess.access_category
    ? JSON.parse(staffAccess.access_category)
    : {};

    if ((staffAccess && accessCategory?.["Client"]?.View) || whoislog === 'Admin') {

      setClientView(true);
    }

    if ((staffAccess && accessCategory?.["Dealer"]?.View) || whoislog === 'Admin') {
      setDealerView(true);
    }

    // Check if the user has access for Dealer, Vehicle, and Update approvals
    if ((staffAccess && accessCategory?.["Pending Request"]?.Dealer) || whoislog === 'Admin') {
      setDealerApproveAccess(true);
    }

    if ((staffAccess && accessCategory?.["Pending Request"]?.Vehicle) || whoislog === 'Admin') {
      setVehicleApproveAccess(true);
    }

    if ((staffAccess && accessCategory?.["Pending Request"]?.Update) || whoislog === 'Admin') {
      setUpdateApproveAccess(true);
    }
  }, []);



  const [countsDtls, setCountsDtls] = useState({
    dealerCount: 0,
    clientCount: 0,
    dealReqCount: 0,
  });
  const [vehCount, setVehCount] = useState(0);
  const [updtCount, setUpdtCount] = useState(0);
  const [uprespondOrder, setUnRespondOrder] = useState([]);
  const [upcomeOrder, setUpcomingOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserListCounts = async () => {
      const accessToken = localStorage.getItem("accessToken");
      try {
        const url = `${BASE_URL}${apiUrl.GET_ALL_USER_DETAILS}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        console.log("Fetched data:", data);

        const dealerCount = data.filter(
          (user) => user["whoislogin"] === "Dealer" && user["Ad_Verify"] === 1
        ).length;
        const clientCount = data.filter(
          (user) => user["whoislogin"] === "Guest" && (user["Ad_Verify"] === 0 || user["Ad_Verify"] === 1)
        ).length;
        const dealReqCount = data.filter(
          (user) => user["whoislogin"] === "Dealer" && user["Ad_Verify"] === 0
        ).length;

        console.log("Counts:", { dealerCount, clientCount, dealReqCount });

        setCountsDtls({ dealerCount, clientCount, dealReqCount });
      } catch (error) {
        console.error("Error fetching user list counts:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchVehRequestCounts = async () => {
      const accessToken = localStorage.getItem("accessToken");
      try {
        const url = `${BASE_URL}${apiUrl.VEHICLE_REQ_COUNT}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        console.log("Fetched vehicle request data:", data);
        const vehReq = data[0].count;
        console.log("Vehicle Request Count:", vehReq);

        setVehCount(vehReq);
      } catch (error) {
        console.error("Error fetching vehicle request counts:", error);
        setError(error);
      }
    };

    const fetchUPdateRequestCount = async () => {
      const accessToken = localStorage.getItem("accessToken");
      try {
        const url = `${BASE_URL}${apiUrl.VEHICLE_UPDATE_REQ_COUNT}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        console.log("Fetched update request data:", data);
        const updtReq = data.count;
        console.log("Vehicle update Count:", updtReq);

        setUpdtCount(updtReq);
      } catch (error) {
        console.error("Error fetching vehicle request counts:", error);
        setError(error);
      }
    };


    const fetchUnRespondOrders= async () => {
      const accessToken = localStorage.getItem("accessToken");
      try {
        const url = `${BASE_URL}${apiUrl.UNRESPOND_ORDER_DETAILS}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const unreponddata = await response.json();
        console.log('unreponddata',unreponddata);
        setUnRespondOrder(unreponddata);
      } catch (error) {
        console.error("Error fetching vehicle request counts:", error);
        setError(error);
      }
    };


    
    const fetchUpcomingOrders= async () => {
      const accessToken = localStorage.getItem("accessToken");
      try {
        const url = `${BASE_URL}${apiUrl.UPCOMING_ORDER_DETAILS}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const upcomedata = await response.json();
        console.log('upcomedata',upcomedata);
        setUpcomingOrder(upcomedata);
      } catch (error) {
        console.error("Error fetching vehicle request counts:", error);
        setError(error);
      }
    };

    fetchUserListCounts();
    fetchVehRequestCounts();
    fetchUPdateRequestCount();
    fetchUnRespondOrders();
    fetchUpcomingOrders();
  }, []);

   if (loading) return <center><div className="waitingspinner"></div></center>;
  // if (error) return <p>Error loading data: {error.message}</p>;


  const handleCardClick = (cardType) => {
    console.log(`${cardType} card clicked`);

    switch (cardType) {
      case "Dealers":
        localStorage.setItem("delrStatus", 'active');
        navigate('/layout/dealerdtls');
        break;
      case "Clients":
        localStorage.setItem("clientStatus", 'active');
        navigate('/layout/clientdtls');
        break;
      case "Dealer Requests":
        navigate('/dealer-requests');
        break;
      case "Vehicle Requests":
        navigate('/vehicle-requests');
        break;
      case "Update Requests":
        navigate('/update-requests');
        break;
      default:
        break;
    }
  };


  const handleView = (bookorder) => {
    navigate('/layout/view_booking_details', { state: { bookorder } });
    console.log('next page view_booking_details',bookorder);
  };

  return (
    <div className="dashboardmain">
      <div className="dashboardmain__wrapper">
        <div className="dashboard__cards">
        {DealerView&&(
          <SingleCard
            item={{
               title: "Dealers", 
               totalNumber: countsDtls.dealerCount,
               icon: "ri-timer-flash-line",
               
              }}
              onClick={() => handleCardClick("Dealers")}
          />
        )}
        {ClientView&&(
          <SingleCard
            item={{ 
              title: "Clients", 
              totalNumber: countsDtls.clientCount,
              icon: "ri-user-line",
            }}
            onClick={() => handleCardClick("Clients")}
          />
          )}
         {DealerApproveAccess&&(
          <SingleCard
            item={{
              title: "Dealer Requests",
              totalNumber: countsDtls.dealReqCount,
              icon: "ri-steering-2-line", 
            }}
            onClick={() => 
              
              handleCardClick("Dealer Requests")}
          />
        )} 
          {VehicleApproveAccess&&(
          <SingleCard
            item={{ 
              title: "Vehicle Requests", 
              totalNumber: vehCount ,
              icon: "ri-police-car-line", 
            }}
            onClick={() => handleCardClick("Vehicle Requests")}
          />
        )} 
          {UpdateApproveAccess&&(
          <SingleCard
            item={{ 
              title: "Vehicle Update Requests", 
              totalNumber: updtCount ,
             icon: "ri-edit-box-line"
            }}
            onClick={() => handleCardClick("Update Requests")}
          />
        )} 
        </div>


      {/* Chart Section */}
    <div className="chart__section">
     <div className="chart1">
     <h3 className="staticstitle">Registration Statics</h3>
        <CarChart />
     </div>
     <div className="chart2">
      <h3 className="staticstitle">Booking Statics</h3>
       <BookingStatsChart/>
     </div>
      
    </div>

    {loading ? (
  <center><div className="waitingspinner"></div></center>
    ) : (
      uprespondOrder.length > 0 && (
        <>
          <h2 className="ongoing__title">Unresponded Order Details</h2>
          <div className="recommend__cars-wrapper">
            {uprespondOrder.map((item) => (
              <RecommendCarCard 
                item={item} 
                onClick={() => handleView(item)}
                key={item.bookid} 
              />
            ))}
          </div>
        </>
      )
    )}




        <h2 className="ongoing__title">Ongoing / Upcoming Order Details</h2>

        {loading ? (
            <center><div className="waitingspinner"></div></center>
          ) : (
            upcomeOrder.length > 0 ? (
              <div className="recommend__cars-wrapper">
                {upcomeOrder.map((item) => (
                  <RecommendCarCard 
                    item={item} 
                    onClick={() => handleView(item)}
                    key={item.bookid} 
                  />
                ))}
              </div>
            ) : (
              <center className='vehno--data'><p>No Bookings found...!</p></center>
            )
          )}


      </div>
    </div>
  );
};

export default Dashboard;
