import React, { useState, useEffect } from "react";
import { BASE_URL } from "../api/config";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis, // Import YAxis
  Tooltip,
  CartesianGrid,
  Area,
  Legend,
} from "recharts";

const CarStatsChart = () => {
  const [chartData, setChartData] = useState([]);
  const [, setError] = useState(null);

  useEffect(() => {
    const fetchDealerStatics = async () => {
      const accessToken = localStorage.getItem("accessToken");
      try {
        const url = `${BASE_URL}/api/Dashboard/fetchDealerClientStatics`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        if (!response.ok) throw new Error("Network response was not ok");
  
        const data = await response.json();
  
        // Preprocess the data to group by year and month
        const groupedData = {};
        data.forEach(({ whoislogin, timestamp }) => {
          const date = new Date(timestamp);
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
                            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const yearMonth = `${monthNames[date.getMonth()]}-${date.getFullYear()}`; // e.g. "Mar-2025"
  
          if (!groupedData[yearMonth]) {
            groupedData[yearMonth] = { name: yearMonth, Dealer: 0, Guest: 0 };
          }
          groupedData[yearMonth][whoislogin]++;
        });
  
        // Convert to array and sort chronologically
        const processedData = Object.values(groupedData).sort((a, b) => {
          return new Date(a.name) - new Date(b.name); // Sort by date
        });
        
        setChartData(processedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      }
    };
    fetchDealerStatics();
  }, []);
  
  if (chartData.length === 0) {
    return <div className="return_chart__section"><p>Loading or no data available...</p></div>;
  }
  return (
    <div style={{ width: "100%", height: "400px" }}>
      <ResponsiveContainer>
        <AreaChart
          data={chartData}
          margin={{ top: 10, right: 30, left: 20, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorDealer" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FF5733" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#FF5733" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorGuest" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#33FF57" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#33FF57" stopOpacity={0} />
            </linearGradient>
          </defs>

          {/* X Axis */}
          <XAxis
            dataKey="name"
            stroke="#000"
            tick={{ fill: "#000", fontSize: 12, fontWeight: "bold" }}
          />

          {/* Y Axis (Added Here) */}
          <YAxis
            stroke="#000"
            tick={{ fill: "#000", fontSize: 12, fontWeight: "bold" }}
          />

          <CartesianGrid strokeDasharray="3 3" stroke="#b7ffe913" />
          <Tooltip
            wrapperClassName="tooltip__style"
            cursor={{ fill: "rgba(255, 255, 255, 0.2)" }}
          />
          <Legend
            verticalAlign="top"
            height={36}
            formatter={(value) => (
              <span style={{ color: "#fff", fontWeight: "bold" }}>{value}</span>
            )}
          />
          <Area
            type="monotone"
            dataKey="Dealer"
            stroke="#FF5733"
            fillOpacity={1}
            fill="url(#colorDealer)"
          />
          <Area
            type="monotone"
            dataKey="Guest"
            stroke="#33FF57"
            fillOpacity={1}
            fill="url(#colorGuest)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CarStatsChart;
