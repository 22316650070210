import React, { useState } from 'react';
import './ChangePassword.css'; // Assuming you'll create a CSS file for styling
import { BASE_URL } from '../../api/config';
import Swal from 'sweetalert2';
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

import { useNavigate } from "react-router-dom";
import { apiUrl } from '../../api/api_url';


const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

const navigate = useNavigate();

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    // Clear previous messages
    setError('');
    setSuccess('');

    // Validation
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password must match.');
      return;
    }
    if (newPassword.length < 6) {
      setError('Password must be at least 6 characters long.');
      return;
    }

    const accessToken = localStorage.getItem("accessToken"); 
    const userDetails = JSON.parse(localStorage.getItem("userDetails")); // Parse the userDetails JSON

    if (!userDetails || !userDetails.staff_id) {
      setError('User details are missing or invalid. Please log in again.');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}${apiUrl.CHANGE_PASSWORD}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userDetails.staff_id, // Pass staff_id to the backend
          oldPassword,
          newPassword,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'Failed to change password. Please try again.');
        return;
      }

      setSuccess(data.message || 'Password changed successfully!');
      Swal.fire({
        title: 'Success!',
        text: 'Password changed successfully',
        icon: 'success',
        timer: 2000,
      }).then(() => {
        setSuccess('');
        localStorage.removeItem("accessToken");
        localStorage.removeItem("Number");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("whoislog");
        localStorage.removeItem("staffaccess");

        sessionStorage.clear();
        localStorage.clear();

        // Redirect to login page
        navigate("/login");
      });
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError('An unexpected error occurred. Please try again later.');
      console.error(err);
    }
  };

  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="dealerdtls-requests">
          <div className="change-password-wrapper">
            <div className="change-password-container">
              <h2 className="change-password-title">Change Password</h2>

              {/* Old Password Field */}
              <div className="input-group">
                <label className="input-label" htmlFor="oldPassword">Old Password</label>
                <div className="password-input-wrapper">
                  <input
                    className="input-field"
                    type={showOldPassword ? "text" : "password"}
                    id="oldPassword"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="Enter your old password"
                  />
                  <span className="toggle-password" onClick={() => setShowOldPassword(!showOldPassword)}>
                    {showOldPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
              </div>

              {/* New Password Field */}
              <div className="input-group">
                <label className="input-label" htmlFor="newPassword">New Password</label>
                <div className="password-input-wrapper">
                  <input
                    className="input-field"
                    type={showNewPassword ? "text" : "password"}
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter a new password"
                  />
                  <span className="toggle-password" onClick={() => setShowNewPassword(!showNewPassword)}>
                    {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
              </div>

              {/* Confirm Password Field */}
              <div className="input-group">
                <label className="input-label" htmlFor="confirmPassword">Confirm New Password</label>
                <div className="password-input-wrapper">
                  <input
                    className="input-field"
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Re-enter your new password"
                  />
                  <span className="toggle-password" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
              </div>

              {/* Error & Success Messages */}
              {error && <div className="message error">{error}</div>}
              {success && <div className="message success">{success}</div>}
        <center>
              {/* Submit Button */}
              <button className="submit-button" onClick={handlePasswordChange}>
                Change Password
              </button>
        </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
