import React from "react";
import { Routes, Route } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import SeataxiBookings from "../pages/SeataxiBookings";
import AllOrderDetails from "../pages/AllOrderDetails";
import Profile from "../pages/Profile";
import MyOrder from "../pages/MyOrders";

import DealerDtls from "../pages/DealerDtls";
import ClientDtls from "../pages/ClientDtls";

import DealerRequest from "../pages/DealerRequest"

import ViewDealerdtl from "../pages/ViewPages/ViewDealerdtl";
import ViewClientdtl from "../pages/ViewPages/ViewClientdtl";
import AddVehTypeCate from "../pages/AddVehType";
import AllVehicleDetails from "../pages/AllVehicleDetails";
import ViewVehicledtl from "../pages/ViewPages/ViewVehicledtl";
import ViewBookingdtl from "../pages/ViewPages/ViewBookingdtl";


import WebContacts from "../pages/WebContacts";
import StaffDetails from "../pages/StaffPages/StaffDetails";
import HistoryDetails from "../pages/HistoryDetails/HistoryDetails";

import ChangePassword from "../components/ChangePassword/ChangePassword";

const Router2 = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/bookings" element={<SeataxiBookings />} />
      <Route path="/allorderdtls" element={<AllOrderDetails />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/myorder" element={<MyOrder />} />
      <Route path="/dealerdtls" element={<DealerDtls />} />
      <Route path="/clientdtls" element={<ClientDtls />} />

      <Route path="/customercontacts" element={<WebContacts />} />
      <Route path="/add_seataxi_staff" element={<StaffDetails />} />
      <Route path="/seataxi_history_details" element={<HistoryDetails />} />
      
      <Route path="/dealer-requests" element={<DealerRequest />} />
      <Route path="/view_dealer_details" element={<ViewDealerdtl />} />
      <Route path="/view_client_details" element={<ViewClientdtl />} />
      <Route path="/addveh_type_cate" element={<AddVehTypeCate />} />
      <Route path="/allVehdetails" element={<AllVehicleDetails />} />
      <Route path="/view_vehicle_details" element={<ViewVehicledtl />} />
      <Route path="/view_booking_details" element={<ViewBookingdtl />} />
     
      <Route path="/change_password" element={<ChangePassword />} />
      
    </Routes>
  );
};

export default Router2;
