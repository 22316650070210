import React from 'react';
import './imagepopup.css';
import defaultImage from '../../assets/images/no_img.jpg'; // Import the image

const ImagePopup = ({ src, alt, onClose }) => {
  const imageSrc = src || defaultImage; // Use imported image as fallback
  console.log('src', src, 'imageSrc', imageSrc);
  
  return (
    <div className="image-popup" onClick={onClose}>
      <div className="image-popup-content">
        <img src={imageSrc} alt={alt} />
        <button className="imgclose-button" onClick={onClose}>
          <i className="ri-close-fill"></i>
        </button>
      </div>
    </div>
  );
};

export default ImagePopup;