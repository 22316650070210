import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../api/config";
import "./viewstyle/viewbookingdtl.css";
import profileImg from "../../assets/images/profile-02.png";
import FeatureList from "../../components/reuseable/FeatureList";

const ViewBookingdtl = () => {
  const location = useLocation();
  const { bookorder } = location.state || {};

  const [isLoading, setIsLoading] = useState(true);



  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    
    // Format date as "Mar 21, 2025"
    const formattedDate = date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short',  // "short" gives abbreviated month (Mar, Apr, etc.)
      day: 'numeric'   // Removes leading zero for day
    });
  
    // Format time as "11.30 AM"
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).replace(':', '.'); // Replace colon with dot
  
    const dateStr = `${formattedDate} ${formattedTime}`;

    return `${dateStr}`;
  };


  useEffect(() => {
    console.log("bookorder", bookorder);
    setIsLoading(false);
    if (bookorder) {
    }
  }, [bookorder]);






  const features = [
    {
      type: "seats",
      value: bookorder.seat ? `${bookorder.seat}` : "",
      color: "green",
    },
    {
      type: "horsepower",
      value: bookorder.power ? `${bookorder.power}` : "",
      color: "red",
    },
    {
      type: "speed",
      value: bookorder.speed ? `${bookorder.speed}` : "",
      color: "blue",
    },
    {
      type: "transmission",
      value: bookorder.gear ? bookorder.gear : "",
      color: "pink",
    },
    {
      type: "luggage",
      value: bookorder.bag ? `${bookorder.bag}` : "",
      color: "yellow",
    },
  ];



  if (isLoading) return <center><div className="waitingspinner"></div></center>;
  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="viewbook-container">
          { bookorder ? (
            <>
              <div className="viewbook_activetitleTop">
                <h2 className="View__book-title">Booking Details</h2>
              </div>

              <div className="bookView__car-top">
                
                <div className="bookView__car-img">
                  <img src={`${BASE_URL}/${bookorder.car_photo}`} alt="" />
                </div>

               <div className="booking__flex">
               <div className="bookvehicle__history">
                  <h2>Booking Details</h2>
                  <div className="book-info">
                    <p>
                      <strong>Booking From:</strong> <span>{bookorder.start_place}</span>
                      &nbsp;
                    </p>
                    <p>
                      <strong>Booking To :</strong> <span>{bookorder.end_place}</span> 
                    </p>
                    <p>
                      <strong>Start Date :</strong> <span>{formatDateTime(bookorder.start_date)}</span>
                    </p>
                    <p>
                      <strong>End Date : </strong> <span>{formatDateTime(bookorder.end_date)}</span>
                    </p>
                    <p>
                      <strong>Number Of Days : </strong> <span>{bookorder.no_days}</span>
                    </p>
                    <p>
                    <strong>Pick-up Time:</strong> <span>{bookorder.pick_time}</span>
                  </p>
                    <p>
                      <strong>Kilometer : </strong> <span>₹ {bookorder.kms} kms</span>
                    </p>
                    <p>
                      <strong>Total Amount : </strong> <span>₹ {bookorder.amt} /-</span>
                    </p>
                    <p>
                      <strong>Booking Purpose : </strong> <span>{bookorder.book_prps}</span>
                    </p>
                  
                    <p>
                      <strong>Current Place : </strong> <span>{bookorder.cur_plc}</span>
                    </p>
                  </div>
                </div>

                <div className="bookvehicle__amt">
                 <div>
                  <h6>Trip Amount</h6>
                  <h1>₹ {bookorder.amt} /-</h1>
                 </div>

                 <div>
                 <h6>Current Status:</h6>
                    <h1
                    style={{ color: getStatusColor(bookorder.verify) }}
                    >
                    {bookorder.verify === "0"
                        ? "Pending"
                        : bookorder.verify === "200"
                        ? "Accepted"
                        : bookorder.verify === "300"
                        ? "Finished"
                        : bookorder.verify === "404"
                        ? "Canceled"
                        : "Unknown"}
                    </h1>
                 </div>
                </div>

               </div>



              </div>

              {/* Vehicle Details Table */}

              <div className="bookvehicle-details">
                <div className="book-info">
               
                <h4 className="title_heading">Vehicle Details</h4>
             
                  <p>
                    <strong>Vehicle Name:</strong> {bookorder.car_name} &nbsp;
                    <span style={{ color: getColor(bookorder.vehAprove) }}>
                      (
                      {bookorder.vehAprove === 0
                        ? "Pending"
                        : bookorder.vehAprove === 1
                        ? "Active"
                        : bookorder.vehAprove === 2
                        ? "Deactive"
                        : "Unknown"}
                      )
                    </span>
                  </p>
                  <p>
                    <strong>Vehicle Number:</strong> {bookorder.car_number}
                  </p>
                  <p>
                    <strong>Cost per KM:</strong> ₹ {bookorder.price_per_km}
                  </p>
                  <p>
                    <strong>Cost per Day:</strong> ₹ {bookorder.one_day_rent}
                  </p>
                </div>

                <div className="subdiv">
                  <div className="bookdriver-details">
                  <h4 className="title_heading">Driver Details</h4>
                    <p>
                      <strong>Driver Name:</strong> {bookorder.dealer.usr_name}
                    </p>
                    <p>
                      <strong>Driver Number:</strong> {bookorder.dealer.usr_num}
                    </p>
                    <p>
                      <strong>Experience:</strong> {bookorder.dealer.experience} years
                    </p>
                    <p>
                      <strong>Licence Number:</strong> {bookorder.dealer.licence_num}
                    </p>
                  </div>
                  <img
                    src={bookorder.dealer.usr_profile != null ? `${BASE_URL}/${bookorder.dealer.usr_profile}` : profileImg}
                    alt={bookorder.dealer.driver_name}
                    className="bookdriver-photo"
                  />

                </div>

                <div className="book-info2">
                <h4 className="title_heading">Customer Details</h4>
                  <p>
                    <strong>Customer Name:</strong> {bookorder.client.usr_name}
                  </p>
                  <p>
                    <strong>Customer Number:</strong> {bookorder.client.usr_num}
                  </p>
                  <p>
                    <strong>Booking Date & Time : </strong> 
                    {new Date(bookorder.dateTime).toLocaleString('en-US', {
                      dateStyle: 'medium',
                      timeStyle: 'short',
                    })}
                  </p>

                  <p className="subdiv2">
                    <strong>Current Status:</strong> &nbsp;
                   
                      {bookorder.verify === "0"
                        ? "Pending"
                        : bookorder.verify === "200"
                        ? "Accepted"
                        : bookorder.verify === "300"
                        ? "Finished"
                        : bookorder.verify === "404"
                        ? "Canceled"
                        : "Unknown"}
                   
                  </p>
                </div>

                <FeatureList features={features} />

              </div>

              
            </>
          ) : (
            <p className="headmargin">No Vehicle data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

const getColor = (verifyStatus) => {
  switch (verifyStatus) {
    case 0:
      return "orange"; // Pending
    case 1:
      return "green"; // Accepted
    case 2:
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};

const getStatusColor = (verifyStatus) => {
  switch (verifyStatus) {
    case "0":
      return "darkorange"; // Pending
    case "200":
      return "green"; // Accepted
    case "300":
      return "darkorange"; // Finished
    case "404":
      return "red"; // Canceled
    default:
      return "gray"; // Unknown
  }
};

export default ViewBookingdtl;
