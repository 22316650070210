/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import "./CatDtlPop.css";
import Swal from "sweetalert2";
import ImagePopup from '../../components/reuseable/ImagePopup';
import FeatureList from "../../components/reuseable/FeatureList";
import { apiUrl } from "../../api/api_url";

const CarDtlPop = ({ vehicle, closeModal, BASE_URL, onAccept, onReject, onDelete }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState("");
  const [updtGallery, setSelectedVehicleGallery] = useState([]);

  useEffect(() => {
    const fetchUpdateGalleryImage = async () => {
      if (!vehicle || !vehicle.token) return;

      setLoading(true);
      setError(null);

      try {
        const accessToken = localStorage.getItem("accessToken");

        const response = await fetch(`${BASE_URL}${apiUrl.GET_PENDING_GALLERY}/${vehicle.token}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch gallery images");
        }

        const data = await response.json();
        setSelectedVehicleGallery(data);
      } catch (err) {
        setError(err.message || "An error occurred while fetching images");
      } finally {
        setLoading(false);
      }
    };

    fetchUpdateGalleryImage();
  }, [vehicle, BASE_URL]);

  const handleDeleteImage = async (imgid, filename) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await fetch(`${BASE_URL}${apiUrl.DELETE_GALLERY_IMAGE}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ img_Id: imgid, filename }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete image");
      }

      Swal.fire({
        title: "Image deleted successfully!",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
        customClass: {
          popup: "custom-popup",
          title: "custom-title",
          content: "custom-content",
        },
      });

      // Refresh gallery images
      setSelectedVehicleGallery((prev) => prev.filter((img) => img.id !== imgid));
    } catch (err) {
      Swal.fire({
        title: "Error!",
        text: err.message || "Failed to delete the image",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setImagePopupOpen(true);
  };

  const closeImagePopup = () => {
    setImagePopupOpen(false);
  };

  const getColor = (verifyStatus) => {
    switch (verifyStatus) {
      case 0:
        return "orange"; // Pending
      case 1:
        return "green"; // Accepted
      case 2:
        return "red"; // Canceled
      default:
        return "gray"; // Unknown
    }
  };



  const features = [
    {
      type: "seats",
      value: vehicle.seat ? `${vehicle.seat}` : "",
      color: "green",
    },
    {
      type: "horsepower",
      value: vehicle.power ? `${vehicle.power}` : "",
      color: "red",
    },
    {
      type: "speed",
      value: vehicle.speed ? `${vehicle.speed}` : "",
      color: "blue",
    },
    {
      type: "transmission",
      value: vehicle.gear ? vehicle.gear : "",
      color: "pink",
    },
    {
      type: "luggage",
      value: vehicle.bag ? `${vehicle.bag}` : "",
      color: "yellow",
    },
  ];


  return (
    <div className="popmodal-overlay">
      <div className="popmodal">
        <button className="popmodal-close-btn" onClick={closeModal}>
          ×
        </button>
        <div className="popmodal-content">
          <div className="imgDIVpop">
          <h2 className="View__car-title">
                  {vehicle.car_type}
                  <span className="car_name">{vehicle.car_name}</span>
                </h2>
            <img
              src={`${BASE_URL}/${vehicle.car_photo}`}
              alt={`${vehicle.car_name} Photo`}
              className="popmodal-image"
            />
             <FeatureList features={features} />
          </div>

          <div className="popmodal-history">
            <div className="popdriver-details">
              <div className="popmodal-details">
                <h4>Vehicle Details</h4>
                <p>
                  <strong>Vehicle Name:</strong> {vehicle.car_name || "N/A"} &nbsp;
                  <span style={{ color: getColor(vehicle.vehAprove) }}>
                    ({vehicle.vehAprove === 0
                      ? "Pending"
                      : vehicle.vehAprove === 1
                      ? "Active"
                      : vehicle.vehAprove === 2
                      ? "Deactive"
                      : "Unknown"})
                  </span>
                </p>
                <p>
                  <strong>Vehicle Number:</strong> {vehicle.car_number || "N/A"}
                </p>
                <p>
                  <strong>Cost per KM:</strong> ₹ {vehicle.price_per_km || "N/A"}
                </p>
                <p>
                  <strong>Cost per Day:</strong> ₹ {vehicle.one_day_rent || "N/A"}
                </p>
                <p>
                  <strong>Minimum KM:</strong> {vehicle.minkm_cost || "N/A"} km
                </p>
                <p>
                  <strong>Minimum KM per Day:</strong> {vehicle.maxkm_day || "N/A"} km
                </p>
              </div>
              <img
                src={
                  vehicle.rcbook_path
                    ? `${BASE_URL}/${vehicle.rcbook_path}`
                    : require("../../assets/images/no_img.jpg")
                }
                alt={vehicle.car_name}
                className="popdocu-photo"
              />
            </div>

            <div className="popdriver-details">
              <div>
                <h4>Driver Details</h4>
                <p>
                  <strong>Driver Name:</strong> {vehicle.usr_name || "N/A"}
                </p>
                <p>
                  <strong>Driver Number:</strong> {vehicle.usr_num || "N/A"}
                </p>
                <p>
                  <strong>Experience:</strong> {vehicle.experience || "N/A"} years
                </p>
                <p>
                  <strong>Licence Number:</strong> {vehicle.licence_num || "N/A"}
                </p>
              </div>
              <img
                src={
                  vehicle.usr_profile
                    ? `${BASE_URL}/${vehicle.usr_profile}`
                    : require("../../assets/images/no_img.jpg")
                }
                alt={vehicle.usr_name}
                className="popdriver-photo"
              />
            </div>
          </div>
        </div>

        <h4 className="glr_title">Gallery:</h4>
        {loading ? (
          <p>Loading images...</p>
        ) : error ? (
          <p>{error}</p>
        ) : updtGallery.length > 0 ? (
          <div className="upgallery">
            {updtGallery.map((image, index) => (
              <div className="upgallery-item" key={index}>
                <i
                  className="ri-close-fill upgaldelete-icon"
                  onClick={() => handleDeleteImage(image.id, image.car_photo)}
                ></i>
                <img
                  src={`${BASE_URL}/${image.car_photo}`}
                  alt={`Gallery Image ${index}`}
                  className="upgallery-image"
                  onClick={() => openImagePopup(`${BASE_URL}/${image.car_photo}`)}
                />
              </div>
            ))}
          </div>
        ) : (
          <p>No Gallery Images Available</p>
        )}

        <div className="popmodal-actions">
          <button className="vehicle-requests__action-btn accept-btn" onClick={onAccept}>
            Accept
          </button>
          <button className="vehicle-requests__action-btn reject-btn" onClick={onReject}>
            Reject
          </button>
          <button className="vehicle-requests__action-btn delete-btn" onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
      
      {imagePopupOpen && (
      <ImagePopup 
        src={popupImageSrc} 
        alt="Vehicle" 
        onClose={closeImagePopup} 
      />
    )}
    </div>
  );
};

export default CarDtlPop;
