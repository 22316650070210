import React from 'react';
import '../assets/css/footer.css';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import main_logo from "../assets/logo/logo.png";
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  // Function to scroll to a specific section
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 70, // Adjust for header height
        behavior: 'smooth',
      });
    }
  };
  
  return (
    <footer className="footer">
      <div className='footer_hed_main_div'>
        <div className='footer_main_div'>
          <div className='footer_main_div_1'>
            <img className='footlogo' src={main_logo} alt="Logo" />
            <p>At Sea Taxi, we are passionate about making your journey memorable and stress-free. Whether you're planning a local gateway or a long-distance adventure, our reliable, comfort and experienced team ensure smooth, comfortable travel every time.</p>
            <div className='footer_social_icons'>
              <a href="https://www.facebook.com/yourPage" target="_blank" rel="noopener noreferrer">
                <FaFacebookF />
              </a>
              <a href="https://www.instagram.com/yourProfile" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </a>
              <a href="https://www.twitter.com/yourHandle" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
            </div>

          </div>
        </div>

        <div className='footer_app_div'>
          <div className='footer_app_div_1'>
            <h2>Quick Links</h2>
            <div className='line_div'></div>
            {/* Quick Links */}
           <div className='footerlinks'>
           <p onClick={() => scrollToSection('home')}>➔ Home</p>
            <p onClick={() => scrollToSection('nearby')}>➔ Near by your location</p>
            <p onClick={() => scrollToSection('how-it-works')}>➔ How it works</p>
            <p onClick={() => scrollToSection('why-choose-us')}>➔ Why choose us</p>
            <p onClick={() => scrollToSection('glide-fleets')}>➔ Our Glide Fleets</p>
            <p onClick={() => scrollToSection('do-you-wish')}>➔ We do more then you wish</p>
            <p onClick={() => scrollToSection('download-app')}>➔ Services</p>
           </div>
          </div>
        </div>

        <div className='footer_app_div'>
          <div className='footer_app_div_1'>
            <h2>Download</h2>
            <div className='line_div'></div>
            <p>Download now and experience hassle-free Sea Taxi app at your fingertips!</p>
            
            <a href="https://play.google.com/store/apps/details?id=com.seataxi.seasense" target="_blank" rel="noopener noreferrer">
            <p>Android Play store</p>
                  </a>
           
           
          </div>
        </div>

        <div className='footer_address_div'>
          <div className='footer_address_div_1'>
            <h2>Contact Details</h2>
            <div className='line_div'></div>
            <a href="tel:+9104651275555">  
            <div className='footerlisttile'>
              <i className="ri-phone-line contact-icon"></i>
              <div>
                <h4>Phone Number</h4>
                <p>04651-275555</p>
              </div>
            </div>
            </a>
             <a href="mailto:seataxi@gmail.com">
            <div className='footerlisttile'>
              <i className="ri-mail-line contact-icon"></i>
              <div>
                <h4>Email Address</h4>
                <p>seataxi@gmail.com</p>
              </div>
            </div>
            </a>
            <div className='footerlisttile'>
              <i className="ri-map-pin-line contact-icon"></i>
              <div>
                <h4>Office Location</h4>
                <p>Near New Bus Stand, Marthandam<br />
                  629165</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='privacyDiv'>
        CopyRight 2024-2025 SEA SENSE | All Right Reserved | <span
          onClick={() => navigate("/PrivacyPolicy")}
        >
          Privacy policy & Conditions
        </span>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
